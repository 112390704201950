<template>
  <div class="mb-5">
    <b-row>
      <b-col cols="8">
        <h4 class="row-dash-title ml--15px">Número de materiais processados</h4>
      </b-col>
      <b-col cols="4">
        <div v-if="loadingExport" class="mr-0 float-right">
          <b-spinner class="load-icon"/>
        </div>
        <div @click="exportData" v-else>
          <external-link-icon
            style="cursor:pointer"
            size="1.5x"
            class="mr-0 float-right"
            v-b-tooltip.hover.right="'Exportar dados'"
          />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="5" class="p-0">
        <b-overlay
          :show="isBusy"
          rounded="sm"
          class="dashboard-overlay"
        >
          <div class="dash-box">
            <DadosProcessadosRosca
              :metaUnidade="metaUnidade"
              :tiposProcessosRealizados="tiposProcessosRealizados"
              tipoMeta="MENSAL"
            />
          </div>
        </b-overlay>
      </b-col>
      <b-col cols="7" class="pr-0">
        <b-overlay
          :show="isBusy"
          rounded="sm"
          class="dashboard-overlay"
        >
          <div class="dash-box">
            <DadosProcessadosBarra
              :metaUnidade="metaUnidade"
              :tiposProcessosRealizados="tiposProcessosRealizados"
              tipoMeta="MENSAL"
            />
          </div>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment';
import XLSX from 'xlsx';

import { mapState } from 'vuex';
import GenericApi from '../../../services/genericRequest';
import DadosProcessadosRosca from './DadosProcessadosRosca';
import DadosProcessadosBarra from './DadosProcessadosBarra';

export default {
  name: 'ProcessadosMensal',
  components: {
    DadosProcessadosRosca,
    DadosProcessadosBarra,
  },
  inject: ['getExportedData'],

  data() {
    return {
      tiposProcessosRealizados: [],
      selectedMonth: parseInt(moment().format('MM'), 10),
      selectedYear: parseInt(moment().format('yyyy'), 10),
      isBusy: true,
      loadingExport: false,
    };
  },

  props: {
    unidadeIndicadores: {
      type: Number,
      required: true,
    },
    date: {
      type: Object,
      required: true,
    },
  },

  watch: {
    unidadeIndicadores: {
      immediate: true,
      deep: true,
      handler() {
        this.getSummaryData();
      },
    },
    date: {
      deep: true,
      handler(d) {
        this.selectedMonth = d.monthIndex;
        this.selectedYear = d.year;
        this.getSummaryData();
      },
    },
  },

  computed: {
    ...mapState(['metas']),
    metaUnidade() {
      if (!this.metas || !this.metas[this.unidadeIndicadores]
          || !this.metas[this.unidadeIndicadores].numeroDeMateriaisProcessadosMensal) return 10000;
      return +this.metas[this.unidadeIndicadores].numeroDeMateriaisProcessadosMensal;
    },
  },

  methods: {
    async getSummaryData() {
      this.isBusy = true;
      const selectedDate = moment().month(this.selectedMonth - 1).year(this.selectedYear);
      try {
        this.tiposProcessosRealizados = await GenericApi.getWithoutPagination(
          {
            filters: {
              id_unidade: this.unidadeIndicadores,
              initDate: selectedDate.clone().startOf('month').toISOString(),
              endDate: selectedDate.clone().endOf('month').toISOString(),
            },
          },
          'home/esterilizacoesGroupedByTipoAndShift',
        );
      } catch (error) {
        let errorMessage = 'Ocorreu um problema ao recuperar os dados relativos aos procedimentos de esterilização';
        if (
          error.response
          && error.response.data
          && error.response.data.error
          && error.response.data.error.errorMessage
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        await swal({
          title: 'Erro',
          text: errorMessage,
          icon: 'error',
          button: 'Continuar...',
        });
      } finally {
        this.isBusy = false;
      }
    },

    async exportData() {
      this.loadingExport = true;
      const selectedDate = moment().month(this.selectedMonth - 1).year(this.selectedYear);
      const filters = {
        id_unidade: this.unidadeIndicadores,
        initDate: selectedDate.clone().startOf('month').toISOString(),
        endDate: selectedDate.clone().endOf('month').toISOString(),
      };
      const exportedData = await this.getExportedData(
        'home/esterilizacoesGroupedByTipoAndShift/export',
        filters,
        'Erro ao recuperar dados de materias processados para exportação',

      );
      this.loadingExport = false;
      if (!exportedData) return;

      this.createXlsx(exportedData, 'materiasProcessados');
    },
    async createXlsx(exportData, fileName = 'dadosExportacao', fileHeader) {
      const header = Array.isArray(fileHeader) ? [...fileHeader] : fileHeader;
      const ws = XLSX.utils.json_to_sheet(exportData, { header });
      if (fileHeader && Array.isArray(fileHeader)) {
        const lastPrintibleColumn = this.colName(fileHeader.length - 1);
        const lastWorksheetColumn = ws['!ref'][ws['!ref'].search(':') + 1];
        ws['!ref'] = ws['!ref'].replace(lastWorksheetColumn, lastPrintibleColumn);
      }
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, ws, 'workbook_to_export');
      XLSX.writeFile(workbook, `${fileName}.xlsx`);
    },

    colName(columnNumber) {
      const ordA = 'A'.charCodeAt(0);
      const ordZ = 'Z'.charCodeAt(0);
      const len = ordZ - ordA + 1;

      let s = '';
      let n = columnNumber;
      while (n >= 0) {
        s = String.fromCharCode((n % len) + ordA) + s;
        n = Math.floor(n / len) - 1;
      }
      return s;
    },
  },
};
</script>

<style scoped>
.load-icon {
  line-height: 1;
  font-size: 36px;
  font-weight: 600;
  color: #209F85;
}
.loading-div {
  text-align: center;
}
</style>
