<template>
  <div>
    <div class="mb-5">
      <div class="modal-wrapper">
        <Modal
          ref="modalListMateriais"
          size="lg"
          id="modalListMateriais"
          @hidden="onModalHide"
        >
          <div
            class="div-selected-materiais no-print"
            v-show="
              modalOptions.tipoDeMaterias === 'materiaisParados' &&
              screenWidth >= 1250
            "
          >
            <div class="div-selected-materiais-inner">
              <h6>Materiais Selecionados:</h6>
              <h6>{{ idMateriasParaEncerrar.length }}</h6>
              <b-button
                type="button"
                variant="none"
                class="btn danger-contained-button button-select-modal"
                style="top: 10px"
                @click="selectOrUnselectAllMateriais"
              >
                {{ selectMaterialButtonName }}
              </b-button>
              <b-button
                type="button"
                variant="none"
                class="btn danger-contained-button button-select-modal"
                v-if="idMateriasParaEncerrar.length"
                @click="showModal"
              >
                Encerrar Ciclos
              </b-button>
            </div>
          </div>
          <PrinterRelatorio
            buttonName="IMPRIMIR"
            hideSection="section"
            hideContent="content"
            :withoutPagination="!withoutPagination"
            @change-pagination="handlePrinterPagination()"
          >
            <template #printContent class="w">
              <div class="header">
                <label class="titleListMateriais">
                  {{ modalOptions.title }}
                </label>
                <label class="dataHoraView">
                  {{ modalOptions.dataHora }}
                </label>
                <div
                  class="no-print"
                  v-show="
                    modalOptions.tipoDeMaterias === 'materiaisParados' &&
                    screenWidth < 1250
                  "
                >
                  <h6>
                    Materiais Selecionados: {{ idMateriasParaEncerrar.length }}
                  </h6>
                  <b-button
                    type="button"
                    variant="none"
                    class="btn danger-contained-button button-select-modal"
                    style="top: 10px"
                    @click="selectOrUnselectAllMateriais"
                  >
                    {{ selectMaterialButtonName }}
                  </b-button>
                  <b-button
                    type="button"
                    variant="none"
                    class="btn danger-contained-button button-select-modal"
                    v-if="idMateriasParaEncerrar.length"
                    @click="showModal"
                  >
                    Encerrar Ciclos
                  </b-button>
                </div>
              </div>
              <b-table
                id="table"
                hover
                :items="modalOptions.materiais"
                :fields="modalOptions.tipoDeMaterias === 'materiaisParados'
                ? materiaisParadosFields
                : modalOptions.tipoDeMaterias === 'materiaisAVencer'
                ? materiaisAVencerFields
                : modalOptions.fields"
                @row-clicked="materialRowClicked"
                :per-page="withoutPagination ? null : perPage"
                :current-page="withoutPagination ? null : currentPage"
              >
                <template #cell(Validade)="{ value }">
                  <span :class="validadeClass(value)">
                    {{ validadeLabel(value) }}
                  </span>
                </template>
                <template #cell(encerrarCiclo)="{ item }">
                  <b-checkbox
                    v-model="item.encerrarCiclo"
                    class="image-checkbox no-print"
                    size="lg"
                    variant="danger"
                  />
                </template>
              </b-table>
              <b-pagination
                v-bind:class="{
                  'd-none': withoutPagination,
                }"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="table"
                align="right"
                class="float-right"
              ></b-pagination>
            </template>
          </PrinterRelatorio>
        </Modal>

        <Modal
          ref="modalEncerramentoCiclo"
          title="Forçar encerramento do ciclo"
          @hidden="hidden"
          no-close-on-esc
          no-close-on-backdrop
          size="md"
          centered
        >
          <b-container v-if="wantsToForceEndCicle">
            <div class="text-confirmation text-center">
              <div>
                Deseja realmente
                <span class="span-text">forçar o encerramento</span>
              </div>
              <div>
                <span class="span-text">
                  do ciclo dos materiais selecionados?</span
                >
              </div>
            </div>
            <br />
            <div class="main-content">
              <span class="span-text"> Id's selecionados: </span>
              <span>
                {{ shownIdsParaEncerrar.join(', ') }}
              </span>
            </div>
            <br />
            <br />
            <div>
              <b-form-group label="Justificativa*">
                <b-form-input
                  id="justificativa"
                  v-model="justificativa"
                  type="text"
                  class="invision-input complement-input"
                  autocomplete="off"
                  name="justificativa"
                  :state="justificativaState"
                />
              </b-form-group>
              <div class="text-right">
                <b-button
                  @click="encerrarCiclo"
                  type="button"
                  class="primary-contained-button"
                  >Confirmar</b-button
                >
              </div>
            </div>
          </b-container>
        </Modal>
      </div>

      <b-row>
        <b-col sm="12" md="8" lg="8" xl="8" class="pl-0 pr-0">
          <!-- Materiais em processamento Linha 1-->
          <b-row>
            <!--card #01-->
            <b-col class="pr-2" :cols="hasUnidades ? '5' : '8'">
              <div
                class="card-data-display-row orange"
                @click="inventarioCardClick('emProcessamento')"
              >
                <div class="pr-3 align-self-center">
                  <div class="icon-circle">
                    <rotate-cw-icon class="dash-icon number-processamento" />
                  </div>
                </div>

                <div class="align-self-center">
                  <div class="" id="row-1-processamento">
                    <b-spinner
                      v-if="isBusy.inventario"
                      class="number-processamento"
                    />
                    <span v-else class="number-processamento mr-1">
                      {{ emProcessamento }}
                    </span>
                    <span class="text-processamento">materiais </span>
                  </div>
                  <div>
                    <span class="text-processamento">em processamento</span>
                  </div>
                </div>
              </div>
            </b-col>
            <!--card #02-->
            <b-col cols="4 pl-2">
              <div
                class="card-inventario light-orange"
                @click="inventarioCardClick('arsenal')"
              >
                <div class="pr-3 align-self-center">
                  <!--icon col-->
                  <div class="icon-circle">
                    <server-icon class="dash-icon number-card-inventario" />
                  </div>
                </div>
                <div class="card-inventario-content align-self-center">
                  <b-spinner
                    v-if="isBusy.inventario"
                    class="number-card-inventario"
                  />
                  <span v-else class="number-card-inventario">
                    {{ inventarioPorArea.arsenal.length }}
                  </span>
                  <span class="text-card-inventario">Arsenal</span>
                </div>
              </div>
            </b-col>
          </b-row>

          <!-- Materiais em processamento Linha 2-->
          <b-row class="mt-3">
            <!--card #01-->
            <b-col cols="4 pr-2">
              <div
                class="card-inventario light-green"
                @click="inventarioCardClick('expurgo')"
              >
                <div class="pr-3 align-self-center">
                  <!--icon col-->
                  <div class="icon-circle">
                    <droplet-icon class="dash-icon number-card-inventario" />
                  </div>
                </div>
                <div class="card-inventario-content align-self-center">
                  <b-spinner
                    v-if="isBusy.inventario"
                    class="number-card-inventario"
                  />
                  <span v-else class="number-card-inventario">
                    {{ inventarioPorArea.expurgo.length }}
                  </span>
                  <span class="text-card-inventario">Expurgo</span>
                </div>
              </div>
            </b-col>
            <!--card #02-->
            <b-col cols="4 px-2">
              <div
                class="card-inventario light-orange"
                @click="inventarioCardClick('preparo')"
              >
                <div class="pr-3 align-self-center">
                  <!--icon col-->
                  <div class="icon-circle">
                    <server-icon class="dash-icon number-card-inventario" />
                  </div>
                </div>
                <div class="card-inventario-content align-self-center">
                  <b-spinner
                    v-if="isBusy.inventario"
                    class="number-card-inventario"
                  />
                  <span v-else class="number-card-inventario">
                    {{ inventarioPorArea.preparo.length }}
                  </span>
                  <span class="text-card-inventario">Preparo</span>
                </div>
              </div>
            </b-col>
            <!--card #03-->
            <b-col cols="4 pl-2">
              <div
                class="card-inventario light-green"
                @click="inventarioCardClick('esterilizacao')"
              >
                <div class="pr-3 align-self-center">
                  <!--icon col-->
                  <div class="icon-circle">
                    <droplet-icon class="dash-icon number-card-inventario" />
                  </div>
                </div>
                <div class="card-inventario-content align-self-center">
                  <b-spinner
                    v-if="isBusy.inventario"
                    class="number-card-inventario"
                  />
                  <span v-else class="number-card-inventario">
                    {{ inventarioPorArea.esterilizacao.length }}
                  </span>
                  <span class="text-card-inventario">Ester./desinf.</span>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-col
            cols=14 class="mt-4"
          >
            <DisponibilizacaoMaterial
              :unidadeIndicadores="unidadeIndicadores"
              :date="date"
            />

          </b-col>
        </b-col>
        <!-- coluna do calendário-->
        <b-col sm="12" md="4" lg="4" xl="4" class="pr-0 pl-3">
          <div style="flex: 1; background-color: white; border-radius: 10px; height: 98.7%" class="toper p-4">
            <DatePicker @onDaySelection="onDaySelection" class="mb-4" style="height: 188px" />
            <div>
              <div class="card-dash-calendar mb-4">
                <div v-if="isBusy.calendar">
                  <b-spinner class="spinner-calendar"></b-spinner>
                </div>
                <span
                  v-else
                  class="title-card-dash-cal"
                  @click="processamentoClicked('materiaisEsterilizados')"
                >
                  {{ processamentoData.materiaisEsterilizados.length }}
                </span>
                <span class="text-card-dash-cal"
                  >Materiais esterilizados / desinfectados</span
                >
              </div>
              <div class="card-dash-calendar mb-4">
                <div v-if="isBusy.calendar">
                  <b-spinner class="spinner-calendar"></b-spinner>
                </div>
                <span v-else class="title-card-dash-cal">{{
                  processamentoData.tempoMedio
                }}</span>
                <span class="text-card-dash-cal"
                  >Tempo médio de processamento</span
                >
              </div>
              <div class="card-dash-calendar mb-4">
                <div v-if="isBusy.calendar">
                  <b-spinner class="spinner-calendar"></b-spinner>
                </div>
                <span
                  v-else
                  class="title-card-dash-cal"
                  @click="processamentoClicked('liberadosArsenal')"
                >
                  {{ processamentoData.liberadosArsenal.length }}
                </span>
                <span class="text-card-dash-cal"
                  >Materiais liberados do arsenal</span
                >
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div>
      <b-col cols=14 class="mt-4">
        <CiclosEsterilizacao
        />
      </b-col>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { mapState } from 'vuex';

import 'echarts/lib/component/title';

import Modal from '../../../components/Utils/Modal';
import GenericApi from '../../../services/genericRequest';
import unidadeService from '../../../services/unidade';
import PrinterRelatorio from '../../../components/Utils/PrinterRelatorio';
import DatePicker from '../../../components/Utils/DatePicker';
import DisponibilizacaoMaterial from './DisponibilizacaoMaterial';
import CiclosEsterilizacao from './CiclosEsterilizacao';

const colorsProdutividade = ['#209F85', '#FF6C0D'];
const unidades = {};

export default {
  name: 'DashBoardInventarioThymos',
  components: {
    Modal,
    DatePicker,
    PrinterRelatorio,
    DisponibilizacaoMaterial,
    CiclosEsterilizacao,
  },
  data() {
    return {
      unidadeIndicadores: 1,
      date: {
        monthIndex: parseInt(moment().format('MM'), 10),
        year: parseInt(moment().format('yyyy'), 10),
      },
      selectedMonth: parseInt(moment().format('MM'), 10),
      mensageNotification: null,
      wantsToForceEndCicle: false,
      showMateriaisParados: true,
      materiaisParados: {
        1: [],
      },
      materiaisAVencer: {},
      materiaisParadosFields: [
        { key: 'Unidade' },
        { key: 'Tipo Material' },
        { key: 'ID' },
        { key: 'Descrição' },
        { key: 'Último Processo' },
        { key: 'Horas/min atrás:' },
        { key: 'encerrarCiclo' },
      ],
      materiaisAVencerFields: [
        { key: 'Tipo Material' },
        { key: 'ID' },
        { key: 'Descrição' },
        { key: 'Qtd de Peças' },
        { key: 'Validade' },
        { key: 'Id Material' },
        { key: 'Id Unidade' },
      ],
      hasNotificacao: false,
      notification: [],
      justificativa: '',

      placeHolderBuscar: null,
      inventarioCard: null,
      subSetorRelatorio: '',
      filtersInventario: {},
      loading: false,
      withoutPagination: false,
      perPage: 10,
      currentPage: 1,
      selectedUnidade: null,
      selectedDay: moment(),
      chartEmprestimo: {
        height: '150px',
        width: '70%',
        color: ['#21CCA9', '#F8C51A', '#3989BF'],
        legend: {
          show: true,
          bottom: '48%',
          right: '0',
          align: 'left',

          orient: 'vertical',
          padding: [24, 24],

          itemWidth: 20,
          itemHeight: 3,

          textStyle: {
            color: 'rgba(94,98,122,1)',
            fontWeight: 'normal',
            fontSize: 12,
          },
        },
        xAxis: [
          {
            type: 'category',
            axisTick: { show: false },
            data: [],
          },
        ],
        yAxis: [{ type: 'value', show: false }],
        series: [],
      },
      chartTipoMaterial: {
        title: {
          text: 0,
          left: '28%',
          top: 'center',
        },
        color: [
          '#209F85',
          '#21CCA9',
          '#73E5CF',
          '#C2F2EA',
          '#FF596A',
          '#FF3348',
          '#FE7A26',
          '#B7D2E5',
          '#5CADE5',
          '#0F7999',
        ],
        legend: {
          right: '10%',
          orient: 'vertical',
          itemWidth: 8,
          itemHeight: 8,
          top: '15%',
          textStyle: {
            color: 'rgba(126,130,159,1)',
            fontWeight: 'normal',
            fontSize: 12,
          },
        },
        tooltip: {
          trigger: 'item',
        },
        series: [
          {
            type: 'pie',
            radius: ['30%', '50%'],
            center: ['30%', '50%'],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: 'inside',
              formatter: '{d}%',
            },
            data: [],
          },
        ],
      },
      chartProdutividade: {
        height: '150px',
        marginTop: '50px',
        legend: { show: true, formatter: '{name}', icon: 'none' },
        xAxis: [
          {
            show: true,
            type: 'category',
            axisTick: { show: false },
            data: [
              'Par Diurno',
              'Par Noturno',
              'Ímpar Diurno',
              'Ímpar Noturno',
            ],
          },
        ],
        yAxis: [{ type: 'value', show: false }],
        series: [
          {
            type: 'bar',
            data: [],
            label: {
              show: true,
              position: 'inside',
            },
          },
        ],
      },
      isBusy: {
        inventario: false,
        emprestimo: false,
        produtividade: false,
        genericos: false,
        calendar: false,
      },
      inventarioPorArea: {
        expurgo: [],
        preparo: [],
        esterilizacao: [],
        arsenal: [],
        transito: [],
      },
      inventarioPorAreaGenericos: {
        expurgo: 0,
        preparo: 0,
        esterilizacao: 0,
        arsenal: 0,
      },
      processamentoData: {
        materiaisEsterilizados: [],
        porTipoMaterial: {},
        tempoMedio: '-',
        liberadosArsenal: [],
      },
      emprestimoData: {},
      produtividadeRangeDates: {
        initDate: moment().startOf('month'),
        endDate: moment().subtract(1, 'day').endOf('day'),
      },
      modalOptions: {
        materiais: [],
        unidade: '',
        processo: '',
      },
      screenWidth: window.innerWidth,
      genericosRangeDates: {
        initDate: moment().subtract(8, 'day').endOf('day'),
        endDate: moment().subtract(1, 'day').endOf('day'),
      },
      dashboardInventarioFilters: {
        material: {
          type: 'input',
          label: 'Filtro',
        },
      },
    };
  },
  computed: {
    ...mapState(['filtersStored']),
    ...mapState({
      unidades: (state) => (
        state.genericData
        && state.genericData.unidade
      ),
    }),
    hasUnidades: function hasUnidades() {
      return this.unidades && this.unidades.length > 1;
    },
    rows() {
      return this.modalOptions.materiais.length;
    },
    emProcessamento() {
      return (
        this.inventarioPorArea.expurgo.length
        + this.inventarioPorArea.preparo.length
        + this.inventarioPorArea.esterilizacao.length
      );
    },
    dashboardInventarioFiltersKeys() {
      return this.dashboardInventarioFilters ? Object.keys(this.dashboardInventarioFilters) : [];
    },
    selectMaterialButtonName() {
      return this.idMateriasParaEncerrar.length
        === this.modalOptions.materiais.length
        ? 'Desselecionar todos'
        : 'Selecionar todos';
    },
    idMateriasParaEncerrar() {
      return this.modalOptions.materiais.reduce(
        (ids, item) => ids.concat(item.encerrarCiclo ? [item.id_material] : []), [],
      );
    },
    hasMateriaisParados() {
      if (this.selectedUnidade) {
        return this.materiaisParados[this.selectedUnidade].length > 0;
      }
      let quantidade1 = 0;
      let quantidade2 = 0;
      let quantidade3 = 0;
      if (this.materiaisParados[1]) {
        quantidade1 = this.materiaisParados[1].length;
      }
      if (this.materiaisParados[2]) {
        quantidade2 = this.materiaisParados[2].length;
      }
      if (this.materiaisParados[3]) {
        quantidade3 = this.materiaisParados[3].length;
      }
      return quantidade1 + quantidade2 + quantidade3 > 0;
    },
    shownIdsParaEncerrar() {
      return this.modalOptions.materiais.reduce(
        (ids, item) => ids.concat(item.encerrarCiclo ? [item.ID] : []), [],
      );
    },
    justificativaState() {
      return Boolean(this.justificativa);
    },
  },
  watch: {
    selectedUnidade() {
      this.fillInventarioData();
      this.fillProcessamentoData();
      this.fillProdutividadeData();
      this.fillGenericosData();
    },
  },
  methods: {
    // TODO Buscar logo do back end e armazenar em cache
    clearMateriaisParaEncerrar() {
      this.modalOptions.materiais.forEach((_, index) => {
        this.modalOptions.materiais[index].encerrarCiclo = false;
      });
    },
    async openModalByNotificacao() {
      this.viewMateriaisParados();
    },
    async openModalByNotificacaoValidade() {
      this.viewMateriaisAVencer();
    },
    async getMateriaisLimiteEsterilizacao() {
      const materiaisLimiteCiclo = await GenericApi.getWithoutPagination(
        {},
        'home/limiteEsterilizacoes/list',
      );
      this.modalOptions.tipoDeMaterias = 'materiaisLimiteCiclo';
      this.modalOptions.materiais = materiaisLimiteCiclo;

      if (this.selectedUnidade) {
        this.modalOptions.materiais = this.modalOptions.materiais
          .filter((elem) => elem.material.id_unidade === this.selectedUnidade);
      }
      this.modalOptions.title = `Materiais próximos ao limite de ciclos${
        !this.selectedUnidade
          ? ''
          : ` na unidade ${unidades[this.selectedUnidade]}`
      }`;
      this.$refs.modalListMateriais.show();
    },

    closeMateriaisParadosWarning() {
      this.showMateriaisParados = false;
    },
    viewMateriaisParados() {
      this.modalOptions.tipoDeMaterias = 'materiaisParados';
      if (!this.selectedUnidade) {
        const keys = Object.keys(this.materiaisParados);
        this.modalOptions.materiais = keys
          .reduce(
            (Acum, key) => [
              ...Acum,
              ...this.materiaisParados[key].map((item) => ({
                ...item,
                id_unidade: key,
              })),
            ],
            [],
          )
          .sort((a, b) => b.diffMins - a.diffMins)
          .map((material) => ({
            Unidade: unidades[material.id_unidade],
            'Tipo Material': material.tipoMaterial,
            ID: material.shownId,
            Descrição: material.descricao,
            'Último Processo': material.ultimoProcesso,
            'Horas/min atrás:': this.convertMinsToHoraMins(material.diffMins),
            encerrarCiclo: false,
            id_material: material.id_material,
          }));
      } else {
        this.modalOptions.materiais = this.materiaisParados[
          this.selectedUnidade
        ]
          .sort((a, b) => b.diffMins - a.diffMins)
          .map((material) => ({
            'Tipo Material': material.tipoMaterial,
            ID: material.shownId,
            Descrição: material.descricao,
            'Último Processo': material.ultimoProcesso,
            'Horas/min atrás:': this.convertMinsToHoraMins(material.diffMins),
            encerrarCiclo: false,
          }));
      }
      this.modalOptions.title = `Materiais Parados${
        !this.selectedUnidade
          ? ''
          : ` na unidade ${unidades[this.selectedUnidade]}`
      }`;
      this.$refs.modalListMateriais.show();
    },
    viewMateriaisAVencer() {
      this.modalOptions.tipoDeMaterias = 'materiaisAVencer';
      if (!this.selectedUnidade) {
        this.modalOptions.materiais = this.materiaisAVencer
          .map((material) => ({
            'Tipo Material': material.tipoMaterial.nome,
            ID: material.shownId,
            Descrição: material.descricao,
            'Qtd de Peças': material.quantidade_itens ? material.quantidade_itens : '-',
            Validade: material.ultimoCiclo.parametroCiclo[0].valor,
          }));
      } else {
        this.modalOptions.materiais = this.materiaisAVencer
          .map((material) => ({
            'Tipo Material': material.tipoMaterial.nome,
            ID: material.shownId,
            Descrição: material.descricao,
            'Qtd de Peças': material.quantidade_itens ? material.quantidade_itens : '-',
            Validade: material.ultimoCiclo.parametroCiclo[0].valor,
            id_material: material.id_material,
            id_unidade: material.ultimoCiclo.ultimoProcessoCiclo
              ? material.ultimoCiclo.ultimoProcessoCiclo.id_unidade : '-',
          }));
        this.modalOptions.materiais = this.modalOptions.materiais
          .filter((material) => material.id_unidade === this.selectedUnidade);
      }
      this.modalOptions.title = `Materiais A Vencer${
        !this.selectedUnidade
          ? ''
          : ` na unidade ${unidades[this.selectedUnidade]}`
      }`;
      this.$refs.modalListMateriais.show();
    },
    hidden() {
      this.wantsToForceEndCicle = !this.wantsToForceEndCicle;
      this.$refs.modalEncerramentoCiclo.hide();
    },
    getLogoHospital(unidade) {
      try {
        // eslint-disable-next-line global-require, import/no-dynamic-require, max-len
        const logo = require(`../../../../../projects/${process.env.VUE_APP_PROJECT}/front-end/assets/img/${unidade.nome.replaceAll(' ', '')}.jpeg`);
        return logo;
      } catch (e) {
        // eslint-disable-next-line global-require
        const logo = require('../../../assets/img/logo.png');
        return logo;
      }
    },
    handlePrinterPagination() {
      this.withoutPagination = !this.withoutPagination;
    },
    selectUnidade(id_unidade) {
      if (this.selectedUnidade === id_unidade) this.selectedUnidade = null;
      else this.selectedUnidade = id_unidade;
      this.$emit('send-selectedUnidade', this.selectedUnidade);
    },
    chartEmprestimoClick(event) {
      this.modalOptions.materiais = this.emprestimoData[event.name][
        event.seriesName
      ];
      this.modalOptions.title = `Materiais emprestados da unidade ${event.name} para unidade ${event.seriesName}`;
      this.modalOptions.tipoDeMaterias = 'materiaisEmprestimo';
      this.modalOptions.fields = [
        { key: 'ID' },
        { key: 'Descrição' },
      ];
      this.currentPage = 1;
      this.$refs.modalListMateriais.show();
    },
    processamentoClicked(type) {
      this.currentPage = 1;
      this.modalOptions.materiais = this.processamentoData[type];
      this.modalOptions.title = type === 'materiaisEsterilizados'
        ? 'Materiais esterilizados / desinfectados'
        : 'Materiais liberados do arsenal';
      this.modalOptions.fields = type === 'materiaisEsterilizados'
        ? [
          { key: 'ID' },
          { key: 'Tipo de Material' },
          { key: 'Descrição' },
          { key: 'Data/Hora Recebimento' },
          { key: 'Data/Hora Esterilização' },
          // { key: 'Tempo Esterilização' },
          { key: 'Duração' },
        ]
        : [
          { key: 'ID' },
          { key: 'Tipo de Material' },
          { key: 'Descrição' },
          { key: 'Data/Hora' },
        ];
      this.modalOptions.tipoDeMaterias = 'materiaisProcessados';
      this.$refs.modalListMateriais.show();
    },
    inventarioCardClick(_subSetor) {
      let subSetor = _subSetor ? String(_subSetor) : null;
      if (!subSetor) {
        subSetor = this.subSetorRelatorio;
      } else {
        this.placeHolderBuscar = 'Buscar por ID ou Descrição';
        this.subSetorRelatorio = subSetor;
        this.inventarioCard = 'inventario';
        this.filtersInventario = {};
      }
      const inventarioFilters = this.getValidFilters();

      const materiais = subSetor !== 'emProcessamento'
        ? this.inventarioPorArea[subSetor]
        : this.inventarioPorArea.expurgo
          .concat(this.inventarioPorArea.preparo)
          .concat(this.inventarioPorArea.esterilizacao);

      if (!materiais || !materiais.length) {
        return;
      }
      if (subSetor === 'transito') {
        this.modalOptions.fields = [
          { key: 'ID' },
          { key: 'Descrição' },
          { key: 'Último Processo' },
          { key: 'Unidade origem' },
          { key: 'Unidade destino' },
          { key: 'Data/Hora' },
        ];
      } else {
        this.modalOptions.fields = [
          { key: 'ID' },
          { key: 'Descrição' },
          { key: 'Último Processo' },
          { key: 'Data/Hora' },
        ];
      }

      if (subSetor === 'arsenal') {
        materiais.sort((a, b) => {
          if (b.validade === a.validade) {
            if (b.timestamp < a.timestamp) return 1;
            return -1;
          }

          if (!a.validade) return 1;
          if (b.validade <= a.validade) return 1;
          return -1;
        });

        this.modalOptions.materiais = materiais.reduce((acc, material) => {
          if (Object.keys(inventarioFilters).length) {
            if (parseInt(inventarioFilters.material, 10)) {
              if (inventarioFilters.material && +inventarioFilters.material !== +material.shownId) return acc;
            } else if (inventarioFilters.material
                        && !material.descricao.match(new RegExp(inventarioFilters.material, 'i'))) return acc;
          }
          return [...acc, {
            ID: material.shownId || '-',
            Descrição: material.descricao,
            'Último Processo': material.tipoProcesso,
            'Data/Hora': moment(material.timestamp).format('DD/MM/YY HH:mm'),
            Validade: material.validade,
          }];
        }, []);
        this.modalOptions.fields.push({ key: 'Validade' });
      } else {
        materiais.sort((a, b) => {
          if (b.timestamp < a.timestamp) return 1;
          return -1;
        });
        this.modalOptions.materiais = materiais.reduce((acc, material) => {
          if (Object.keys(inventarioFilters).length) {
            if (parseInt(inventarioFilters.material, 10)) {
              if (inventarioFilters.material && +inventarioFilters.material !== +material.shownId) return acc;
            } else if (inventarioFilters.material
                        && !material.descricao.match(new RegExp(inventarioFilters.material, 'i'))) return acc;
          }
          if (subSetor === 'transito') {
            return [...acc, {
              ID: material.shownId || '-',
              Descrição: material.descricao,
              'Unidade origem': material.unidadeOrigem,
              'Unidade destino': material.unidadeDestino,
              'Último Processo': material.tipoProcesso,
              'Data/Hora': moment(material.timestamp).format('DD/MM/YY HH:mm'),
            }];
          }
          return [...acc, {
            ID: material.shownId || '-',
            Descrição: material.descricao,
            'Último Processo': material.tipoProcesso,
            'Data/Hora': moment(material.timestamp).format('DD/MM/YY HH:mm'),
          }];
        }, []);
      }

      let unidadeView = '';
      if (this.selectedUnidade) {
        unidadeView = `- ${unidades[this.selectedUnidade]}`;
      }
      this.modalOptions.dataHora = moment().format('DD/MM/YYYY - HH:mm');
      this.modalOptions.title = `Materiais em ${this.getNomeProcessoByChave(
        subSetor,
      )} ${unidadeView}`;
      this.modalOptions.tipoDeMaterias = 'materiaisInventario';
      this.currentPage = 1;
      this.$refs.modalListMateriais.show();
    },

    onDaySelection(day) {
      this.selectedDay = day;
      this.fillProcessamentoData();
    },
    async fillInventarioData() {
      const id_unidade = this.selectedUnidade;
      this.isBusy.inventario = true;
      const filters = {};
      if (id_unidade) {
        filters.id_unidade = id_unidade;
      }
      try {
        this.inventarioPorArea = await GenericApi.getWithoutPagination(
          { filters },
          'home/inventario',
        );
      } catch (e) {
        console.log('error in fillInventarioData:', e);
        await swal({
          title: 'Oops!',
          text: 'Ocorreu um problema ao obter dados de inventário',
          icon: 'error',
          button: 'Continuar...',
        });
        this.selectedUnidade = null;
      } finally {
        this.isBusy.inventario = false;
      }
    },
    async fillProcessamentoData() {
      const id_unidade = this.selectedUnidade;
      const day = this.selectedDay;
      this.isBusy.calendar = true;

      const filters = { day: day.toISOString() };
      if (id_unidade) {
        filters.id_unidade = id_unidade;
      }
      try {
        const {
          esterilizados,
          liberadosArsenal,
          tempoMedioMins,
        } = await GenericApi.getWithoutPagination(
          { filters },
          'home/processamento',
        );
        this.processamentoData.materiaisEsterilizados = esterilizados;
        this.processamentoData.porTipoMaterial = esterilizados.reduce(
          (Acum, material) => ({
            ...Acum,
            [material['Tipo de Material']]: Acum[material['Tipo de Material']]
              ? [...Acum[material['Tipo de Material']], material.id_material]
              : [material.id_material],
          }),
          {},
        );

        const tiposMateriais = Object.keys(
          this.processamentoData.porTipoMaterial,
        );

        this.chartTipoMaterial.title.text = this.processamentoData.materiaisEsterilizados.length.toString();
        this.chartTipoMaterial.series[0].data = tiposMateriais
          .map((tipoMaterial) => ({
            name: tipoMaterial,
            value: this.processamentoData.porTipoMaterial[tipoMaterial].length,
          }))
          .sort((a, b) => b.value - a.value);
        this.processamentoData.liberadosArsenal = liberadosArsenal;
        this.processamentoData.tempoMedio = this.convertMinsToHoraMins(
          tempoMedioMins,
        );
      } catch (e) {
        console.log('error in fillProcessamentoData:', e);
      } finally {
        this.isBusy.calendar = false;
      }
    },
    async fillEmprestimoData() {
      this.isBusy.emprestimo = true;
      try {
        const result = await GenericApi.getWithoutPagination(
          {},
          'home/emprestimo',
        );
        this.emprestimoData = result;

        this.chartEmprestimo.xAxis.data = [];
        this.chartEmprestimo.series = [];
        Object.keys(result).forEach((key) => {
          this.chartEmprestimo.xAxis[0].data.push(key);

          let currentSeries = this.chartEmprestimo.series.find((s) => s.name === key);
          if (!currentSeries) {
            currentSeries = {
              name: key,
              type: 'bar',
              data: Object.keys(result).map(() => null),
              label: { show: true, position: 'top', fontWeight: 700 },
            };
            this.chartEmprestimo.series.push(currentSeries);
          }

          Object.keys(result).forEach((key2, index) => {
            const emprestimos = result[key2][key];
            currentSeries.data[index] = emprestimos ? emprestimos.length : 0;
          });
        });
      } catch (e) {
        console.log('error in fillEmprestimoData:', e);
      } finally {
        this.isBusy.emprestimo = false;
      }
    },
    async fillProdutividadeData() {
      const id_unidade = this.selectedUnidade;
      this.isBusy.produtividade = true;
      const { initDate, endDate } = this.produtividadeRangeDates;
      const filters = {
        initDate: initDate.toISOString(),
        endDate: endDate.toISOString(),
      };
      if (id_unidade) {
        filters.id_unidade = id_unidade;
      }
      try {
        const result = await GenericApi.getWithoutPagination(
          { filters },
          'home/produtividade',
        );
        this.chartProdutividade.series[0].data = result.map((value, index) => ({
          itemStyle: { color: colorsProdutividade[index % 2] },
          value,
        }));
      } catch (e) {
        console.log('error in fillProdutividadeData:', e);
      } finally {
        this.isBusy.produtividade = false;
      }
    },
    rangeDateSelected(initDate, endDate) {
      this.produtividadeRangeDates.initDate = initDate;
      this.produtividadeRangeDates.endDate = endDate;
      this.fillProdutividadeData();
    },
    async fillGenericosData() {
      const id_unidade = this.selectedUnidade;
      this.isBusy.genericos = true;
      const { initDate, endDate } = this.genericosRangeDates;
      const filters = {
        initDate: initDate.toISOString(),
        endDate: endDate.toISOString(),
      };
      if (id_unidade) {
        filters.id_unidade = id_unidade;
      }
      try {
        this.inventarioPorAreaGenericos = await GenericApi.getWithoutPagination(
          { filters },
          'home/inventarioGenericos',
        );
      } catch (e) {
        console.log('error in fillGenericosData:', e);
      } finally {
        this.isBusy.genericos = false;
      }
    },
    genericosRangeDateSelected(initDate, endDate) {
      this.genericosRangeDates.initDate = initDate;
      this.genericosRangeDates.endDate = endDate;
      this.fillGenericosData();
    },
    convertMinsToHoraMins(mins) {
      const hours = Math.floor(mins / 60);
      const hoursStr = hours ? `${hours}h ` : '';
      const minutes = mins % 60;
      const minStr = minutes >= 10 ? `${minutes}mins` : `0${minutes}mins`;
      return `${hoursStr}${minStr}`;
    },
    getNomeProcessoByChave(chaveProcesso) {
      switch (chaveProcesso) {
        case 'expurgo':
          return 'Expurgo';
        case 'preparo':
          return 'Preparo';
        case 'esterilizacao':
          return 'Esterilização';
        case 'arsenal':
          return 'Arsenal';
        case 'emProcessamento':
          return 'Processamento';
        case 'transito':
          return 'Transito';
        default:
          return '-';
      }
    },

    materialRowClicked({ id_material, ID }) {
      if (!id_material || !ID) return;
      const routeData = this.$router.resolve({
        path: `material/rastrear/${id_material}`,
      });
      window.open(routeData.href, '_blank');
    },
    validadeLabel(value) {
      return value ? moment(value).format('DD/MM/YYYY') : '-';
    },
    validadeClass(validade) {
      if (!validade || moment(validade).isBefore(moment())) return 'red-bold';
      return '';
    },

    handleChangeDashboardInventarioFilters(key, value) {
      this.filtersInventario[key] = value;
    },

    handleSearch() {
      this.page = 1;
      if (this.inventarioCard && this.inventarioCard === 'inventarioGenericos') {
        this.inventarioCardGenericosClick();
      } else {
        this.inventarioCardClick();
      }
    },

    getValidFilters() {
      return Object.keys(this.filtersInventario).reduce((Acum, key) => {
        if (!this.filtersInventario[key]) return Acum;
        if (!this.dashboardInventarioFiltersKeys.includes(key)) {
          return Acum;
        }
        return {
          ...Acum,
          [key]: this.filtersInventario[key],
        };
      }, {});
    },

    onModalHide() {
      this.clearMateriaisParaEncerrar();
    },

    async encerrarCiclo() {
      if (!this.justificativa) {
        await swal({
          title: 'Erro',
          text: 'Justificativa é obrigatória para encerrar o ciclo!',
          icon: 'error',
          button: 'Continuar...',
        });

        return;
      }
      try {
        await GenericApi.create(
          {
            idsMaterial: this.idMateriasParaEncerrar,
            justificativa: this.justificativa,
          },
          'forceCiclo/finishLote',
        );
        await swal({
          title: 'Bom trabalho!',
          text: 'Os ciclos foram encerrados com sucesso!',
          icon: 'success',
          button: 'Continuar',
        });
        this.clearMateriaisParaEncerrar();
        await this.checkMateriaisParados();
        this.$refs.modalListMateriais.hide();
        this.$refs.modalEncerramentoCiclo.hide();
        this.justificativa = '';
      } catch (error) {
        let errorMessage;
        if (
          error.response
          && error.response.data
          && error.response.data.error
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        swal({
          title: 'Oops!',
          text:
            errorMessage
            || 'Ocorreu um problema ao finalizar o ciclo desse material',
          icon: 'error',
          button: 'Continuar',
        });
      }
    },

    selectOrUnselectAllMateriais() {
      if (
        this.idMateriasParaEncerrar.length
        === this.modalOptions.materiais.length
      ) {
        this.clearMateriaisParaEncerrar();
        this.modalOptions.materiais.forEach((item, index) => {
          this.modalOptions.materiais[index].encerrarCiclo = false;
        });
        return;
      }
      this.clearMateriaisParaEncerrar();
      this.modalOptions.materiais.forEach((item, index) => {
        this.modalOptions.materiais[index].encerrarCiclo = true;
      });
    },

    async checkMateriaisParados() {
      try {
        this.materiaisParados = await GenericApi.getWithoutPagination(
          {},
          'home/materiaisParados',
        );
      } catch (e) {
        console.log('error in checkMateriaisParados:', e);
      }
    },

    async checkMateriaisAVencer() {
      try {
        this.materiaisAVencer = await GenericApi.getWithoutPagination(
          {},
          'home/MateriaisAVencer',
        );
      } catch (e) {
        console.log('error in checkMateriaisAVencer');
      }
    },

    showModal() {
      this.wantsToForceEndCicle = !this.wantsToForceEndCicle;
      this.$refs.modalEncerramentoCiclo.show();
    },

    chartProdutividadeClick() {},
  },

  async mounted() {
    this.checkMateriaisParados();
    this.checkMateriaisAVencer();
    this.fillInventarioData();
    this.fillProcessamentoData();
    this.fillEmprestimoData();
    this.fillProdutividadeData();
    this.fillGenericosData();
    window.addEventListener('resize', () => {
      this.screenWidth = window.innerWidth;
    });
    if (!this.unidades) {
      unidadeService.getUnidades();
    }

    const resultNotification = await GenericApi.getAllWithoutPagination({}, 'home/notificacao/list');
    if (resultNotification) {
      this.hasNotification = true;
      this.notification = resultNotification;
      this.mensageNotification = await (
        this.notification.find((not) => not.tipo === 'processamento')
      ).descricao || null;
    }
    this.hasNotificacao = Boolean(resultNotification);
  },
};
</script>

<style lang='scss' scoped>
#printSection table tbody tr{
  cursor: pointer  !important;
}

.w {
  background-color: #fff;
}
.m1 {
  margin-left: -2px;
}

.red-bold {
  color: red;
  font-weight: bold;
}

.warning-text {
  color: #dc3545;
}

.col-foto-unidade {
  min-height: 210px;
  box-sizing: border-box;
}

.foto-unidade {
  border-radius: 5px;
  height: 100%;
  width: 100%;
  cursor: pointer;

}

.foto-label {
  padding: 24px;

  color: #ffffff;
  font-size: 24px;
  font-weight: 600;

  z-index: 1;
}

.card-overlay {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 16.85%, #0D8068 71.32%);
  opacity: 0.7;
  position: absolute;
  top: 0;
  right: 15px;
  left: 15px;
  bottom: 0;
  border-radius: 5px;
}

.card-data-display-row {
  display: flex;
  padding: 24px;
  border-radius: 10px;

  cursor: pointer;
}

.icon-circle {
  border-radius: 50%;
  width: 60px !important;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dash-icon {
  width: 40%;
  height: 40%;
}

.number-processamento {
  line-height: 1;
  font-size: 36px;
  font-weight: 600;
  color: #ffffff;
}
.text-processamento {
  line-height: 0;
  font-size: 16px;
  color: #ffffff;
}

.card-inventario {
  display: flex;
  padding: 24px;
  border-radius: 10px;

  cursor: pointer;
}

.small-card-inventario {
  display: flex;
  padding: 24px 0 24px 8px;
  border-radius: 10px;

  cursor: pointer;
}
.number-card-inventario {
  font-size: 36px;
  line-height: 1;
  font-weight: 600;
}

.card-inventario-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text-card-inventario {
  font-size: 16px;
  color: #5e627a;
}

.green .text-card-inventario {
  font-size: 16px;
  color: #ffffff;
}

#grafico-emprestimo.col {
  max-width: 99.4%;
}

.chart-box {
  background-color: #ffffff;
  border-radius: 10px;
  max-height: 265px;
}

#chartEmprestimo {
  padding-top: 20px;
  height: 263px;
}

.orange {
  background-color: rgba(255, 108, 13, 0.84);
  color: #ffffff;
}
.light-orange {
  background-color: rgba(255, 100, 0, 0.1);
}
.light-green {
  background-color: rgba(33, 204, 169, 0.1);
}
.green {
  background-color: #21cca9;
}

.light-orange .number-card-inventario {
  color: rgba(255, 100, 0, 0.84);
}

.light-green .number-card-inventario {
  color: #209f85;
}

.green .number-card-inventario {
  color: #fff;
}

.light-orange .icon-circle {
  background-color: rgba(255, 108, 13, 0.3);
}
.green .icon-circle {
  background-color: rgba(255, 255, 255, 0.3);
}

.light-green .icon-circle {
  background-color: rgba(33, 204, 169, 0.3);
}
.orange .icon-circle {
  background-color: rgba(250, 195, 159, 0.2);
}

.bs-border {
  box-sizing: border-box;
}

.inner-row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

// .icon-col {

//   justify-content: center;
//   align-items: center;
// }

//calendario
.opac-60 {
  opacity: 0.6;
}

.card-dash-calendar {
  padding: 24px;
  border-radius: 10px;
  width: 100%;
  height: 189px;
  background-color: rgba(33, 204, 169, 0.1);
  display: flex;
  flex-direction: column;
}
.title-card-dash-cal {
  cursor: pointer;
  font-size: 36px;
  font-weight: 600;
  color: #21cca9;
}
.text-card-dash-cal {
  font-size: 18px;
  font-weight: 400;
  color: #5e627a;
}

.p-8 {
  padding: 8px;
}
//

.evenItem {
  background-color: #fff;
}
.oddItem {
  background-color: #f7f7fa;
}

.chart-titles {
  position: absolute;
  top: 24px;
  font-size: 18px;
  font-weight: 500;
  color: #5e627a;
}

#total-esterilizado-chart {
  position: absolute;
  z-index: 9;
  top: 52%;
  left: 24%;
  font-size: 32px;
  font-weight: 500;
  color: #35384d;
}

.RangeDatePicker-in-graph {
  position: absolute;
  top: 24px;
  right: 0%;
}
.div-selected-materiais {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 5px;
  right: -10%;
  z-index: 1500;
  float: right;
}

.div-selected-materiais-inner {
  background-color: #ffffff;
  padding: 24px;
  border-radius: 10px;
  border: 2px solid #f7f7fa;
  z-index: 1500;
  position: absolute;
  width: 220px;
}

.button-select-modal {
  margin: 5px;
  font-size: 0.8rem;
  width: 170px;
}

@media print {
  .no-print {
    display: none !important;
  }
}

.main-content {
  width: 30em;
  border: 2px dashed rgb(178, 176, 215);
  border-radius: 5px;
  padding: 5%;
}

.span-text {
  color: #00daae;
}

.generic-titles-box {
  position: relative;
  bottom: 6px;
  z-index: 99;
}

.generic-titles {
  position: relative;
  top: 24px;
  font-size: 18px;
  font-weight: 500;
  color: #5e627a;
}

.RangeDatePicker-in-genericos {
  position: absolute;
  top: 24px;
  right: 0%;
}

#filter-box {
  display: flex;
  width: 50%;
}

#filter-button {
  background: #F7F7FA;
  height: 28px;
  width: 28px;
  margin-top: 1px;
  margin-left: -30px;
  padding: 0;
}

.filter-icon {
  color:#a4a4aa;
}

#filter-input {
  background: #F7F7FA;
}
.titleListMateriais {
  width: 65%;
  text-align-last: left;
}
.dataHoraView {
  //color:#fff;
  width: 35%;
  text-align-last: right;
}
</style>
