<template>
  <div class="mb-5">
    <b-row>
      <b-col cols="10">
        <h4 class="row-dash-title">Preparo - Tempo de processamento</h4>
      </b-col>
      <b-col cols="2">
        <div v-if="loadingExport || loading" class="float-right">
          <b-spinner class="load-icon"/>
        </div>
        <div @click="exportData" v-else class="float-right">
          <external-link-icon
            style="cursor:pointer"
            size="1.5x"
            v-b-tooltip.hover.right="'Exportar dados'"
          />
        </div>
      </b-col>
    </b-row>
    <b-overlay
      :show="loading"
      rounded="sm"
      class="dashboard-overlay"
    >
      <div class="dash-box">
        <ECharts :option="chartPreparoMensal" />
      </div>
    </b-overlay>
  </div>
</template>
<script>
import moment from 'moment';
import ECharts from 'vue-echarts';

import GenericApi from '../../../services/genericRequest';

export default {
  name: 'TempoProcesPreparoMensal',
  components: {
    ECharts,
  },

  inject: ['getExportedData', 'createXlsx'],

  props: {
    unidadeIndicadores: {
      type: Number,
      required: true,
    },
    date: {
      type: Object,
      required: true,
    },
  },

  watch: {
    unidadeIndicadores: {
      deep: true,
      handler() {
        this.updateChart();
      },
    },
    date: {
      deep: true,
      handler(d) {
        this.selectedMonth = d.monthIndex;
        this.selectedYear = d.year;
        this.updateChart();
      },
    },
  },

  data() {
    return {
      loading: false,
      loadingExport: false,
      selectedMonth: parseInt(moment().format('MM'), 10),
      selectedYear: parseInt(moment().format('yyyy'), 10),
      tempoProcessamentoByShift: [],
      chartPreparoMensal: {
        grid: [
          {
            show: false,
            left: 10,
            right: 10,
            top: 30,
            bottom: 38,
            borderColor: '#D4D4D4',
          },
        ],
        xAxis: [
          {
            type: 'category',
            axisTick: {
              show: false,
            },
            data: ['Par diurno', 'Par noturno', 'Ímpar diurno', 'Ímpar noturno'],
          },
        ],
        yAxis: [{ type: 'value', show: false }],
        series: [
          {
            name: 'Par diurno',
            type: 'bar',
            barWidth: '50%',
            data: [
              {
                itemStyle: { color: '#FF7D29' },
                value: 0,
              },
              {
                itemStyle: { color: '#FF934D' },
                value: 0,
              },
              {
                itemStyle: { color: '#FF3348' },
                value: 0,
              },
              {
                itemStyle: { color: '#FF5A6B' },
                value: 0,
              },
            ],
            label: {
              show: true,
              position: 'top',
              formatter: (v) => {
                const h = Math.floor(v.value / 60) ? `${Math.floor(v.value / 60)}h` : '';
                const m = Math.floor(v.value % 60);
                return `${h}${m}m`;
              },
            },
          },
        ],
      },
    };
  },
  methods: {
    async updateChart() {
      // const colors = ['#21CCA9', '#209F85', '#3989BF', '#73E6CF'];
      await this.getSummaryData();
      this.tempoProcessamentoByShift.forEach((processo) => {
        let index;
        if (processo.oddOrEven === 'odd') {
          if (processo.shift === 'noturno') index = 3;
          else index = 2;
        } else if (processo.shift === 'noturno') index = 1;
        else index = 0;
        const tempoMedioMin = moment.duration(parseInt(processo.tempoMedio, 10)).asMinutes();
        this.chartPreparoMensal.series[0].data[index].value = tempoMedioMin;
      });
    },

    async getSummaryData() {
      const selectedDate = moment()
        .month(this.selectedMonth - 1)
        .year(this.selectedYear);

      try {
        this.loading = true;

        this.tempoProcessamentoByShift = await GenericApi.getWithoutPagination(
          {
            filters: {
              id_unidade: this.unidadeIndicadores,
              initDate: selectedDate.clone().startOf('month').toISOString(),
              endDate: selectedDate.clone().endOf('month').toISOString(),
              tipoProcesso: 'preparo',
            },
          },
          'home/tempoProcessamentoGroupedByShift',
        );
        return true;
      } catch (error) {
        let errorMessage = 'Ocorreu um problema ao recuperar os dados relativos aos procedimentos de ciclo';
        if (
          error.response
          && error.response.data
          && error.response.data.error
          && error.response.data.error.errorMessage
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        await swal({
          title: 'Erro',
          text: errorMessage,
          icon: 'error',
          button: 'Continuar...',
        });
        return false;
      } finally {
        this.loading = false;
      }
    },

    minutesToHourMinutes(min) {
      if (!min) return '0h';
      const hours = Math.floor(min / 60);
      const minutes = Math.floor(min % 60);
      const hoursString = hours ? `${hours}h` : '';
      const minutesString = minutes ? `${minutes}m`.padStart(3, '0') : '';
      return `${hoursString}${minutesString}`;
    },

    async exportData() {
      this.loadingExport = true;
      const selectedDate = moment()
        .month(this.selectedMonth - 1)
        .year(this.selectedYear);

      const filters = {
        id_unidade: this.unidadeIndicadores,
        initDate: selectedDate.clone().startOf('month').toISOString(),
        endDate: selectedDate.clone().endOf('month').toISOString(),
        tipoProcesso: ['preparo'],
      };
      const exportedData = await this.getExportedData(
        'home/tempoProcessamentoPlantao/export',
        filters,
        'Erro ao recuperar dados de preparo para exportação',
      );
      if (!exportedData) {
        this.loadingExport = false;
        return;
      }
      const processosExported = exportedData.map((processo) => {
        const data_processamento = moment(processo.data_processamento);
        const data_finalizacao = moment(processo.data_finalizacao);
        const tempoProcessamento = data_finalizacao.diff(data_processamento, 'minutes');
        processo.evenOrOddDay = processo.evenOrOddDay === 'odd' ? 'ímpar' : 'par';
        processo.Turno = `${processo.Turno} - ${processo.evenOrOddDay}`;
        delete processo.evenOrOddDay;
        delete processo.vouchers_inicio;
        return {
          ...processo,
          data_processamento: data_processamento.format('DD/MM/YYYY HH:mm:ss'),
          data_finalizacao: data_finalizacao.isValid()
            ? data_finalizacao.format('DD/MM/YYYY HH:mm:ss')
            : '-',
          'Tempo Processamento': data_finalizacao.isValid() ? this.minutesToHourMinutes(tempoProcessamento) : '-',
        };
      });
      const fileName = `Preparo ${selectedDate.format('MMMM')}`;
      this.createXlsx(processosExported, fileName);
      this.loadingExport = false;
    },
  },

  mounted() {
    this.updateChart();
  },
};
</script>
