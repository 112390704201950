<template>
  <div class="mb-5">
     <b-row>
      <b-col cols="8">
        <h4 class="row-dash-title">Expurgo - Tempo de processamento</h4>
      </b-col>
    </b-row>
    <b-overlay
      :show="isBusy"
      rounded="sm"
      class="dashboard-overlay"
    >
      <div class="dash-box">
        <ECharts :option="chartExpurgoSemestral" />
      </div>
    </b-overlay>
  </div>
</template>
<script>
import moment from 'moment';
import ECharts from 'vue-echarts';

import GenericApi from '../../../services/genericRequest';

export default {
  name: 'TempoProcesExpurgoSemestral',
  components: {
    ECharts,
  },

  props: {
    unidadeIndicadores: {
      type: Number,
      required: true,
    },
    selectedSemester: {
      type: Object,
      required: true,
    },
  },

  watch: {
    unidadeIndicadores: {
      immediate: true,
      deep: true,
      handler() {
        if (this.firstUpdate) this.updateChart();
      },
    },
    selectedSemester: {
      deep: true,
      handler(semester) {
        this.selectedYear = semester.year;
        if (this.firstUpdate) this.updateChart();
      },
    },
  },

  data() {
    return {
      firstUpdate: false,
      selectedYear: parseInt(moment().format('yyyy'), 10),
      isBusy: true,
      ProcessamentoByMonth: [],
      mesesAbrev: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      chartExpurgoSemestral: {
        grid: [
          {
            show: false,
            left: 10,
            right: 10,
            top: 30,
            bottom: 40,
            borderColor: '#D4D4D4',
          },
        ],
        color: [
          '#3989BF',
        ],
        xAxis: [
          {
            type: 'category',
            axisTick: {
              show: false,
            },
            data: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun'],
          },
        ],
        yAxis: [{ type: 'value', show: false }],
        series: [
          {
            name: 'Par diurno',
            type: 'bar',
            barWidth: '50%',
            data: [90, 100, 99, 150, 30, 187],
            label: {
              show: true,
              position: 'top',
              formatter: (v) => {
                const h = Math.floor(v.value / 60) ? `${Math.floor(v.value / 60)}h` : '';
                const m = Math.floor(v.value % 60);
                return `${h}${m}m`;
              },
            },
          },
        ],
      },
    };
  },

  mounted() {
    this.updateChart();
    this.firstUpdate = true;
  },

  methods: {
    async updateChart() {
      this.isBusy = true;
      await this.getSummaryData();
      const { ProcessamentoByMonth } = this;
      const offsetMonthIndex = this.selectedSemester.semester === 1 ? 0 : 6;
      const xAxisData = this.mesesAbrev.slice(offsetMonthIndex, 12 + (offsetMonthIndex - 6));

      const processamentoChartData = ProcessamentoByMonth.reduce((result, processo) => {
        const indexOfMonth = processo.mes - offsetMonthIndex;
        const ChartDataPerMonth = result;
        moment.duration(parseInt(processo.tempoMedio, 10)).asMinutes();
        const tempoMedioMili = processo.quantidadeProcessos > 0
          ? processo.tempoTotal / processo.quantidadeProcessos
          : 0;
        ChartDataPerMonth[indexOfMonth] = moment.duration(parseInt(tempoMedioMili, 10)).asMinutes();
        return ChartDataPerMonth;
      }, [0, 0, 0, 0, 0, 0]);
      this.chartExpurgoSemestral.xAxis[0].data = xAxisData;

      this.chartExpurgoSemestral.series[0].data = processamentoChartData;
      this.isBusy = false;
    },

    async getSummaryData() {
      const initDate = this.selectedSemester.beginDate.clone().startOf('month');
      const endDate = this.selectedSemester.endDate.clone().endOf('month');
      try {
        this.ProcessamentoByMonth = await GenericApi.getWithoutPagination(
          {
            filters: {
              id_unidade: this.unidadeIndicadores,
              initDate: initDate.toISOString(),
              endDate: endDate.toISOString(),
              tipoProcesso: 'expurgo',
            },
          },
          'home/tempoProcessamentoGroupedByMonth',
        );
        return true;
      } catch (error) {
        let errorMessage = 'Ocorreu um problema ao recuperar os dados relativos ao tempo de expurgo';
        if (
          error.response
          && error.response.data
          && error.response.data.error
          && error.response.data.error.errorMessage
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        await swal({
          title: 'Erro',
          text: errorMessage,
          icon: 'error',
          button: 'Continuar...',
        });
        return false;
      }
    },
  },
};
</script>
