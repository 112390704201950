<template>
  <div>
    <b-row>
      <b-col cols="7">
        <h4 class="row-dash-title">Quantidade de ciclos por dia</h4>
      </b-col>
      <b-col cols="2">
        <RangeDatePicker
          :initDate="dateRange.initDate"
          :endDate="dateRange.endDate"
          :state="null"
          :isBusy="loading"
          @selected="selectedDateInDatePicker"
        />
      </b-col>
      <b-col cols="2">
        <GenericSelect
            ref="selectTipoProcessamento"
            name="tipoProcessamento"
            labelKey="nome"
            v-model="id_tipo_esterilizacao"
            route="tipoEsterilizacao"
            :disabled="loading"
            v-validate="{ required: true }"
            :customFilters="filtersTipoEsterilizacao"
            :firstOption="selectFirstOption"
            :state="null"
          ></GenericSelect>
      </b-col>
      <b-col cols="1">
        <div v-if="loadingExport || loading">
          <b-spinner class="load-icon"/>
        </div>
        <div @click="exportData" v-else>
          <external-link-icon
            style="cursor:pointer"
            size="1.5x"
            v-b-tooltip.hover.right="'Exportar dados'"
          />
        </div>
      </b-col>
    </b-row>
    <b-overlay
      :show="loading"
      rounded="sm"
      class="dashboard-overlay"
    >
      <div class="dash-box">
        <b-row>
          <b-col cols="12">
            <ECharts :option="chartOptions" />
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import moment from 'moment';
import ECharts from 'vue-echarts';

import RangeDatePicker from '../../../components/Form/RangeDatePicker';
import GenericSelect from '../../../components/Form/GenericSelect';
import GenericApi from '../../../services/genericRequest';

export default {
  name: 'ProdutividadeArea',
  components: {
    ECharts,
    GenericSelect,
    RangeDatePicker,
  },
  inject: ['getExportedData', 'createXlsx'],
  props: {
    unidadeIndicadores: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      loadingExport: false,
      dateRange: {
        initDate: moment().subtract(7, 'days').startOf('day'),
        endDate: moment().endOf('day'),
      },
      filtersTipoEsterilizacao: {
        withoutTipo: '70',
      },
      selectFirstOption: {
        value: null,
        label: 'todos',
      },
      mountedFirstTime: false,
      id_tipo_esterilizacao: 1,
      chartOptions: {
        color: ['#FF7D29', '#21CCA9'],
        legend: {
          data: ['Diurno', 'Noturno'],
          left: '10%',
          bottom: 10,
          textStyle: {
            fontSize: 12,
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        xAxis: [
          {
            show: true,
            type: 'category',
            axisTick: { show: false },
            data: [],
            splitLine: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            show: true,
            splitLine: {
              show: false,
            },
            max: (value) => Math.round(value.max * 1.1 + 20 - ((value.max * 1.1) % 10)),
          },
        ],
        series: [
          {
            name: 'Diurno',
            type: 'bar',
            stack: 'diurno',
            data: [],
            label: {
              show: true,
              position: 'top',
              rotate: 90,
              verticalAlign: 'middle',
              offset: [10, 0],
            },
            itemStyle: {
              borderRadius: [20, 20, 0, 0],
            },
          },
          {
            type: 'bar',
            name: 'Noturno',
            stack: 'Noturno',
            data: [],
            label: {
              show: true,
              position: 'top',
              rotate: 90,
              verticalAlign: 'middle',
              offset: [10, 0],
            },
            itemStyle: {
              borderRadius: [20, 20, 0, 0],
            },
          },
        ],
      },
    };
  },

  mounted() {
    this.updateChart();
    this.mountedFirstTime = true;
  },

  computed: {
    idsTipoEsterilizacao() {
      if (this.id_tipo_esterilizacao) return [this.id_tipo_esterilizacao];
      if (!this.$refs.selectTipoProcessamento) return [];
      const ids = this.$refs.selectTipoProcessamento.options.map((item) => item.value);
      return ids;
    },
  },

  watch: {
    id_tipo_esterilizacao() {
      if (this.mountedFirstTime) this.updateChart();
    },
    dateRange: {
      deep: true,
      async handler() {
        if (this.mountedFirstTime) this.updateChart();
      },
    },
    unidadeIndicadores: {
      deep: true,
      async handler() {
        if (this.mountedFirstTime) this.updateChart();
      },
    },
  },

  methods: {
    selectedDateInDatePicker(initDate, endDate) {
      this.dateRange.initDate = initDate;
      this.dateRange.endDate = endDate;
    },

    async updateChart() {
      const esterilizacoesByShift = await this.getChartData();
      this.chartOptions.series[0].data = [];
      this.chartOptions.series[1].data = [];
      if (esterilizacoesByShift) this.updateChartData(esterilizacoesByShift);
    },

    async getChartData() {
      // o turno termina de um dia termina no dia seguinte as 7 horas
      const endShiftDate = this.dateRange.endDate.clone()
        .startOf('day').add(1, 'day').set('hour', 7)
        .subtract(1, 'second');
      const filters = {
        id_unidade: this.unidadeIndicadores,
        initDate: this.dateRange.initDate.toISOString(),
        endDate: endShiftDate.toISOString(),
        id_tipo_esterilizacao: this.id_tipo_esterilizacao,
      };
      try {
        this.loading = true;

        const esterilizacoesByShift = await GenericApi.getWithoutPagination(
          { filters },
          'home/esterilizacoesGroupedByDayAndShift',
        );
        return esterilizacoesByShift;
      } catch (error) {
        let errorMessage = 'Ocorreu um problema ao recuperar os dados relativos à ciclos por dia';
        if (
          error.response
            && error.response.data
            && error.response.data.error
            && error.response.data.error.errorMessage
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        await swal({
          title: 'Erro',
          text: errorMessage,
          icon: 'error',
          button: 'Continuar...',
        });
        return false;
      } finally {
        this.loading = false;
      }
    },

    updateChartData(esterilizacoesByShift) {
      esterilizacoesByShift.forEach((processosDia, index) => {
        this.chartOptions.series[0].data[index] = processosDia.processosDiurno;
        this.chartOptions.series[1].data[index] = processosDia.processosNoturno;
      });
      const initDay = this.dateRange.initDate.clone().subtract(1, 'day');
      const numberOfDays = esterilizacoesByShift.length;
      const days = Array.from({ length: numberOfDays }, () => initDay.add(1, 'day').format('DD/MM'));
      this.chartOptions.xAxis[0].data = days;
    },

    async exportData() {
      this.loadingExport = true;
      // o turno termina de um dia termina no dia seguinte as 7 horas
      const endShiftDate = this.dateRange.endDate.clone()
        .startOf('day').add(1, 'day').set('hour', 7)
        .subtract(1, 'second');
      const initShiftDate = this.dateRange.initDate.clone().startOf('day').set('hour', 7);

      const filters = {
        id_unidade: this.unidadeIndicadores,
        initDate: initShiftDate.toISOString(),
        endDate: endShiftDate.toISOString(),
        id_tipo_esterilizacao: this.idsTipoEsterilizacao,
      };
      const exportedData = await this.getExportedData(
        'home/processamento/export',
        filters,
        'Erro ao recuperar dados de processamentos diários para exportação',
      );
      if (!exportedData) {
        this.loadingExport = false;
        return;
      }
      const processosExported = exportedData.map((processo) => {
        const data_processamento = moment(processo.data_processamento).format('DD/MM/YYYY HH:mm:ss');
        return {
          ...processo,
          data_processamento,
        };
      });
      this.createXlsx(processosExported, 'Processamentos Diários');
      this.loadingExport = false;
    },
  },
};
</script>

<style scoped>
.meta-box {
  width: 45%;
  font-size: 2.2rem;
  border: 1px solid #cfd1e5;
  border-radius: 10px;
  padding: 12px;
  font-weight: 500;
}
.info-text {
  color: rgba(126, 130, 159, 1);
  font-weight: normal;
  font-size: 0.7rem;
}

.result-meta {
  font-size: 0.7rem;
  position: absolute;
  top: 2.9rem;
  margin-left: 10px;
}
.result-meta.negativo {
  color: red;
}
.result-meta.positivo {
  color: green;
}
</style>
