<template>
  <div>
    <b-row>
      <b-col cols="7">
        <h4 class="row-dash-title">Registros de não conformidade</h4>
      </b-col>
      <b-col cols="2">
        <RangeDatePicker
          :initDate="dateRange.initDate"
          :endDate="dateRange.endDate"
          :state="null"
          :isBusy="loading"
          @selected="selectedDateInDatePicker"
        />
      </b-col>
      <b-col cols="2">
        <GenericSelect
            ref="tipoMaterial"
            name="tipoMaterial"
            labelKey="nome"
            v-model="id_tipo_material"
            route="tipoMaterial"
            :customFilters="filtersTipoMaterial"
            :disabled="loading"
            :firstOption="selectFirstOption"
            :state="null"
          ></GenericSelect>
      </b-col>
      <b-col cols="1">
        <div v-if="loadingExport || loading" class="mr-0">
          <b-spinner class="load-icon"/>
        </div>
        <div @click="exportData" v-else>
          <external-link-icon
            style="cursor:pointer"
            size="1.5x"
            class="mr-0"
            v-b-tooltip.hover.right="'Exportar dados'"
          />
        </div>
      </b-col>
    </b-row>
    <b-overlay
      :show="loading"
      rounded="sm"
      class="dashboard-overlay"
    >
      <div class="dash-box">
        <b-row>
          <b-col cols="12">
            <ECharts :option="chartOptions" />
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import moment from 'moment';
import ECharts from 'vue-echarts';

import RangeDatePicker from '../../../components/Form/RangeDatePicker';
import GenericSelect from '../../../components/Form/GenericSelect';
import GenericApi from '../../../services/genericRequest';

export default {
  name: 'ProdutividadeArea',
  components: {
    ECharts,
    GenericSelect,
    RangeDatePicker,
  },
  inject: ['getExportedData', 'createXlsx'],
  props: {
    unidadeIndicadores: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dateRange: {
        initDate: moment().subtract(7, 'days').startOf('day'),
        endDate: moment().endOf('day'),
      },
      selectFirstOption: {
        value: null,
        label: 'todos',
      },
      filtersTipoMaterial: {
        onlyTipos: ['Rede', 'Hospital', 'Vídeo', 'Médico'],
      },
      loading: false,
      loadingExport: false,
      mountedFirstTime: false,
      id_tipo_material: 1,
      chartOptions: {
        color: ['#FF7D29', '#21CCA9', '#FF3348'],
        legend: {
          left: '10%',
          bottom: 10,
          textStyle: {
            fontSize: 12,
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        xAxis: [
          {
            show: true,
            type: 'category',
            axisTick: { show: false },
            data: [],
            splitLine: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            show: true,
            splitLine: {
              show: false,
            },
            position: 'left',
          },
          {
            min: 0,
            max: (value) => Math.max(value.max, 100),
            position: 'right',
            splitLine: {
              show: false,
            },
            axisLabel: {
              formatter: '{value}%',
            },
          },
        ],
        series: [
          {
            name: 'Quantidade',
            type: 'bar',
            stack: 'quantidade',
            data: [],
            label: {
              show: true,
              position: 'top',
              verticalAlign: 'middle',
            },
          },
          {
            type: 'bar',
            name: 'Não Conformidade',
            stack: 'naoConformidade',
            data: [],
            label: {
              show: true,
              position: 'top',
              verticalAlign: 'middle',
            },
          },
          {
            type: 'line',
            yAxisIndex: 1,
            name: '%',
            stack: 'porcentagem',
            data: [],
          },
        ],
      },
    };
  },

  computed: {
    idsTipoMaterial() {
      if (this.id_tipo_material) return [this.id_tipo_material];
      if (!this.$refs.tipoMaterial) return [];
      const ids = this.$refs.tipoMaterial.options.map((item) => item.value);
      return ids;
    },
  },

  mounted() {
    this.updateChart();
    this.mountedFirstTime = true;
  },

  watch: {
    idsTipoMaterial() {
      if (this.mountedFirstTime) this.updateChart();
    },
    dateRange: {
      deep: true,
      async handler() {
        if (this.mountedFirstTime) this.updateChart();
      },
    },
    unidadeIndicadores: {
      deep: true,
      async handler() {
        if (this.mountedFirstTime) this.updateChart();
      },
    },
  },

  methods: {
    selectedDateInDatePicker(initDate, endDate) {
      this.dateRange.initDate = initDate;
      this.dateRange.endDate = endDate;
    },

    async updateChart() {
      this.loading = true;

      const naoConformidades = await this.getChartData();
      this.createEmptyChart();
      if (naoConformidades) this.updateChartData(naoConformidades);

      this.loading = false;
    },

    async getChartData() {
      const filters = {
        id_unidade: this.unidadeIndicadores,
        initDate: this.dateRange.initDate.toISOString(),
        endDate: this.dateRange.endDate.toISOString(),
        id_tipo_material: this.idsTipoMaterial,
      };
      try {
        const esterilizacoesByShift = await GenericApi.getWithoutPagination(
          { filters },
          'home/naoConformidades',
        );
        return esterilizacoesByShift;
      } catch (error) {
        let errorMessage = 'Ocorreu um problema ao recuperar os dados relativos à não conformidades';
        if (
          error.response
            && error.response.data
            && error.response.data.error
            && error.response.data.error.errorMessage
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        await swal({
          title: 'Erro',
          text: errorMessage,
          icon: 'error',
          button: 'Continuar...',
        });
        return false;
      }
    },

    updateChartData(naoConformidades) {
      naoConformidades.forEach((processo) => {
        const initDay = this.dateRange.initDate.clone().startOf('week');
        const weekDate = moment().set('year', processo.ano).set('week', processo.semana + 1).day(0);
        const weekIndex = weekDate.diff(initDay, 'week');
        this.chartOptions.series[0].data[weekIndex] = processo.quantidade_itens;
        this.chartOptions.series[1].data[weekIndex] = processo.totalNaoConformidades;
        this.chartOptions.series[2].data[weekIndex] = processo.quantidade_itens > 0
          ? ((processo.totalNaoConformidades * 100) / processo.quantidade_itens).toFixed(1)
          : 0;
      });
    },
    createEmptyChart() {
      const initDay = this.dateRange.initDate.clone().startOf('week');
      const endDay = this.dateRange.endDate.clone().endOf('week');
      const numberOfWeeks = endDay.diff(initDay, 'week') + 1;
      const weeks = Array.from({ length: numberOfWeeks }, (v, k) => `Semana ${k + 1}`);
      this.chartOptions.series.forEach((serie) => {
        serie.data = Array(numberOfWeeks).fill(0);
      });
      this.chartOptions.xAxis[0].data = weeks;
    },
    async exportData() {
      this.loadingExport = true;
      const filters = {
        id_unidade: this.unidadeIndicadores,
        initDate: this.dateRange.initDate.toISOString(),
        endDate: this.dateRange.endDate.toISOString(),
        id_tipo_material: this.idsTipoMaterial,
      };
      const exportedData = await this.getExportedData(
        'home/naoConformidades/export',
        filters,
        'Erro ao recuperar dados sobre processos com não conformidade para exportação',

      );
      if (!exportedData) {
        this.loadingExport = false;
        return;
      }
      const processosExported = exportedData.map((processo) => {
        const data_processamento = moment(processo.data_processamento).format('DD/MM/YYYY HH:mm:ss');
        return {
          ...processo,
          data_processamento,
        };
      });
      this.createXlsx(processosExported, 'processos nao conformidade');
      this.loadingExport = false;
    },
  },
};
</script>

<style scoped>

.meta-box {
  width: 45%;
  font-size: 2.2rem;
  border: 1px solid #cfd1e5;
  border-radius: 10px;
  padding: 12px;
  font-weight: 500;
}
.info-text {
  color: rgba(126, 130, 159, 1);
  font-weight: normal;
  font-size: 0.7rem;
}

.result-meta {
  font-size: 0.7rem;
  position: absolute;
  top: 2.9rem;
  margin-left: 10px;
}
.result-meta.negativo {
  color: red;
}
.result-meta.positivo {
  color: green;
}

</style>
