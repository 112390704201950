<template>
  <div>
    <b-row class="mb-36" >
      <b-col sm="12" md="4" lg="4" xl="4" align="left" class="unidade-div-align">
        <GenericSelect
          name="unidade"
          labelKey="nome"
          route="unidade"
          :value="unidadeIndicadores"
          @input="v => $emit('change-unidade-indicadores', v)"
          disabledFirstOption
        />
      </b-col>
      <b-col sm="9" md="4" lg="3" xl="3" align="left" class="mt-auto mb-auto">
        <SemesterCarousel @onChangeSemester="onChangeSemester" />
      </b-col>
    </b-row>
    <br>
    <ProcessadosSemestral
      :unidadeIndicadores="unidadeIndicadores"
      :selectedSemester="semester"
    />
    <ControleBnpSemestral
      :unidadeIndicadores="unidadeIndicadores"
      :selectedSemester="semester"
    />
    <b-row class="mb-5">
      <b-col cols="5" class="p-0">
        <PacotesCicloSemestral
          :unidadeIndicadores="unidadeIndicadores"
          :selectedSemester="semester"
        />
      </b-col>
      <b-col cols="7" class="pr-0">
        <ReprocessamentoSemestral
          :unidadeIndicadores="unidadeIndicadores"
          :selectedSemester="semester"
        />
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col cols="5" class="p-0">
        <TempoProcesExpurgoSemestral
          :unidadeIndicadores="unidadeIndicadores"
          :selectedSemester="semester"
        />
      </b-col>
      <b-col cols="7" class="pr-0">
        <TempoProcesPreparoSemestral
          :unidadeIndicadores="unidadeIndicadores"
          :selectedSemester="semester"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment';
import SemesterCarousel from '../../../components/Form/SemesterCarousel';
import GenericSelect from '../../../components/Form/GenericSelect';

import ProcessadosSemestral from './ProcessadosSemestral';
import ControleBnpSemestral from './ControleBnpSemestral';
import PacotesCicloSemestral from './PacotesCicloSemestral';
import ReprocessamentoSemestral from './ReprocessamentoSemestral';
import TempoProcesExpurgoSemestral from './TempoProcesExpurgoSemestral';
import TempoProcesPreparoSemestral from './TempoProcesPreparoSemestral';

export default {
  name: 'DashBoardIndicadoresSemestral',
  components: {
    GenericSelect,
    ProcessadosSemestral,
    ControleBnpSemestral,
    PacotesCicloSemestral,
    ReprocessamentoSemestral,
    TempoProcesExpurgoSemestral,
    TempoProcesPreparoSemestral,
    SemesterCarousel,
  },
  data() {
    return {
      semester: {
        beginDate: +moment().format('MM') < 7 ? moment().set('month', 0) : moment().set('month', 6),
        endDate: +moment().format('MM') < 7 ? moment().set('month', 5) : moment().set('month', 11),
        semester: +moment().format('MM') < 7 ? 1 : 2,
        year: +moment().format('yyyy'),
      },
    };
  },
  props: {
    unidadeIndicadores: {
      type: Number,
      required: true,
    },
  },
  methods: {
    onChangeSemester(semester) {
      this.semester = semester;
    },
  },
};
</script>

<style>

.unidade-div-align{
  left: -18px;
}

</style>
