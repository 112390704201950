<template>
  <div>
    <b-row>
      <b-col cols="8">
        <h4 class="row-dash-title">Reprocessamento de materiais</h4>
      </b-col>
    </b-row>
    <b-overlay
      :show="isBusy"
      rounded="sm"
      class="dashboard-overlay"
    >
      <div class="dash-box">
        <b-row class="info-content" align="center">
          <b-col cols="2.5">
            <div class="meta-box text-center">
              <div class="info-text">LIMITE</div>
              <span class="result-meta">
                {{ limite }}
              </span>
            </div>
          </b-col>

          <b-col cols="4">
            <b-row v-for="(motivo, index) in chartOptions.series
              .filter((s) => s.name !== 'LIMITE')" :key="`motivo${index}`">
              <div class="subtitle">
                <label class="label-square" :style="`background-color: ${chartOptions.color[index]};`"></label>
                <span>{{ motivo.name }}</span>
              </div>
            </b-row>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <ECharts
              @click="chartClick"
              :option="chartOptions"
            />
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import ECharts from 'vue-echarts';
import GenericApi from '../../../services/genericRequest';

export default {
  name: 'ReprocessamentoMensal',
  components: {
    ECharts,
  },

  computed: {
    ...mapState(['metas']),
    limite() {
      if (!this.metas || !this.metas[this.unidadeIndicadores]
          || !this.metas[this.unidadeIndicadores].limiteDeReprocessamentoDeMateriaisSemestral) return 100;
      return +this.metas[this.unidadeIndicadores].limiteDeReprocessamentoDeMateriaisSemestral;
    },
  },

  data() {
    return {
      firstUpdate: false,
      selectedYear: parseInt(moment().format('yyyy'), 10),
      isBusy: true,
      materiaisReprocessedByMonth: [],
      mesesAbrev: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      chartOptions: {
        height: '220px',
        grid: [
          {
            show: false,
            left: 10,
            right: 10,
            top: 30,
            bottom: 38,
          },
        ],
        color: [
          '#FF3348',
          '#73E5CF',
          '#3989BF',
          '#21CCA9',
          '#F8C51A',
          '#C2F2EA',
          '#B7D2E5',
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        xAxis: [{
          show: true,
          type: 'category',
          axisTick: { show: false },
          data: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun'],
        }],
        yAxis: [{ show: false }],
        series: [{
          type: 'line',
          name: 'LIMITE',
          showSymbol: false,
          color: '#FF3348',
          data: [100, 100, 100, 100, 100, 100],
          label: {
            show: false,
          },
        },
        ],
      },
    };
  },

  props: {
    unidadeIndicadores: {
      type: Number,
      required: true,
    },
    selectedSemester: {
      type: Object,
      required: true,
    },
  },

  watch: {
    unidadeIndicadores: {
      immediate: true,
      deep: true,
      handler() {
        if (this.firstUpdate) this.updateChart();
      },
    },
    selectedSemester: {
      deep: true,
      handler(semester) {
        this.selectedYear = semester.year;
        if (this.firstUpdate) this.updateChart();
      },
    },
  },

  async mounted() {
    this.updateChart();
    this.firstUpdate = true;
  },

  methods: {
    async resetSeriesData() {
      this.chartOptions.series.forEach((series) => {
        series.data = [0, 0, 0, 0, 0, 0];
      });
      await this.$nextTick();

      this.chartOptions.series.length = 1;
    },

    async updateChart() {
      this.isBusy = true;
      await this.resetSeriesData();
      await this.getMateriaisReprocessedByMonth();
      this.setUpChartOptions();

      const offsetMonthIndex = this.selectedSemester.semester === 1 ? 0 : 6;
      const xAxisData = this.mesesAbrev.slice(offsetMonthIndex, 12 + (offsetMonthIndex - 6));
      this.initReprocessamentoMensalData(offsetMonthIndex);
      this.chartOptions.xAxis[0].data = xAxisData;

      this.isBusy = false;
    },
    chartClick() {},

    isCritical(value) {
      return value <= 10;
    },

    setUpChartOptions() {
      this.chartOptions.series.push(...Object.keys(this.materiaisReprocessedByMonth)
        .filter((key) => key !== 'count' && key !== 'tiposMaterial')
        .map((key) => ({
          type: 'bar',
          name: key,
          stack: 'principal',
          data: [],
        })));
    },

    getDataValueByDate(date, serieName) {
      if (this.materiaisReprocessedByMonth[serieName].count > 0) {
        moment.locale('pt-br');
        const countReprocessamentoMes = this.materiaisReprocessedByMonth[serieName].reprocessamentoDates
          .filter((reprocessamentoDate) => moment(reprocessamentoDate).format('MMM')
              === this.mesesAbrev[date].toLowerCase())
          .length;
        return countReprocessamentoMes;
      }
      return 0;
    },

    async getMateriaisReprocessedByMonth() {
      const initDate = this.selectedSemester.beginDate.clone().startOf('month');
      const endDate = this.selectedSemester.endDate.clone().endOf('month');
      try {
        this.materiaisReprocessedByMonth = await GenericApi.getWithoutPagination(
          {
            filters: {
              id_unidade: this.unidadeIndicadores,
              initDate: initDate.utcOffset(0, true).toISOString(),
              endDate: endDate.utcOffset(0, true).toISOString(),
            },
          },
          'home/reprocessamentoMateriais',
        );
        return true;
      } catch (error) {
        let errorMessage = 'Ocorreu um problema ao recuperar os dados relativos aos procedimentos de ciclo';
        if (
          error.response
          && error.response.data
          && error.response.data.error
          && error.response.data.error.errorMessage
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        await swal({
          title: 'Erro',
          text: errorMessage,
          icon: 'error',
          button: 'Continuar...',
        });
        return false;
      }
    },

    async initReprocessamentoMensalData(semester) {
      const arrayReason = this.chartOptions.series.map((s) => s.name).filter((n) => n !== 'LIMITE');
      const semesterMonth = semester === 0 ? 6 : 12;

      for (let i = 0; i < arrayReason.length; i++) {
        for (let j = semester; j < semesterMonth; j++) {
          this.chartOptions.series[i + 1].data.push(this.getDataValueByDate(j, arrayReason[i]));
        }
      }
      this.isBusy = false;
    },
  },
};

</script>

<style scoped>
.label-square {
  height: 6%;
  border-radius: 10%;
  width: 11%;
  position: absolute;
  margin: 0;
  border-radius: 0.1rem;
}

.label-square.data-vencida {
  background-color: #FF3348;
}

.label-square.embalagem-violada {
  background-color: #73E6CF;
}

.label-square.reposicao-comodato {
  background-color: #3989BF;
}

.label-square.cancelamento-procedimento {
  background-color: #21CCA9;
}

.subtitle {
  display: flex;
  justify-content: "space-evenly";
  align-items: center;
  margin-bottom: 0.77rem;
}

.subtitle span{
  font-weight: 500;
  font-size: 10px;
  margin-left: 2rem;
}

.meta-box {
  max-width: 100%;
  width: 100%;
  border: 1px solid #CFD1E5;
  border-radius: 10px;
  padding: 12px 12px 0px 12px;
  font-weight: 500;
}

.meta-box span {
  font-weight: 500;
  font-size: 36px;
}

.info-content {
  padding: 0.5rem 2rem 0 2rem;
  display: flex;
  justify-content: space-between;
}

.result-meta.negative {
  color: #FF3348;
}

.result-meta.positive {
  color: #21CCA9;
}
.result-meta {
  position: inherit;
}

span.small-rate {
  font-size: 12px;
}

</style>
