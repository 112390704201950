<template>
  <div>
    <b-row class="mb-36" >
      <b-col cols="4" align="left" class="unidade-div-align">
        <GenericSelect
          name="unidade"
          labelKey="nome"
          route="unidade"
          :value="unidadeIndicadores"
          @input="v => $emit('change-unidade-indicadores', v)"
          disabledFirstOption
        />
      </b-col>
      <b-col cols="3" class="p-0 unidade-div-align ">
        <MonthPicker @input="onMonthSelection" :value="selectedMonth" />
      </b-col>
    </b-row>
    <ProcessadosMensal
      :unidadeIndicadores="unidadeIndicadores"
      :date="date"
    />
    <ControleBnpMensal
      :unidadeIndicadores="unidadeIndicadores"
      :date="date"
    />
    <b-row class="mb-5">
      <b-col cols="5" class="p-0">
        <PacotesCicloMensal
          :unidadeIndicadores="unidadeIndicadores"
          :date="date"
        />
      </b-col>
      <b-col cols="7" class="pr-0">
        <ReprocessamentoMensal
          :unidadeIndicadores="unidadeIndicadores"
          :date="date"
        />
      </b-col>
    </b-row>

    <b-row class="mb-5">
      <b-col cols="5" class="p-0">
        <TempoProcesExpurgoMensal
          :unidadeIndicadores="unidadeIndicadores"
          :date="date"
        />
      </b-col>
      <b-col cols="7" class="pr-0">
        <TempoProcesPreparoMensal
          :unidadeIndicadores="unidadeIndicadores"
          :date="date"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment';

import GenericSelect from '../../../components/Form/GenericSelect';
import MonthPicker from '../../../components/Form/MonthPicker';

import ProcessadosMensal from './ProcessadosMensal';
import ControleBnpMensal from './ControleBnpMensal';
import PacotesCicloMensal from './PacotesCicloMensal';
import ReprocessamentoMensal from './ReprocessamentoMensal';
import TempoProcesExpurgoMensal from './TempoProcesExpurgoMensal';
import TempoProcesPreparoMensal from './TempoProcesPreparoMensal';

export default {
  name: 'DashBoardIndicadoresMensal',
  components: {
    GenericSelect,
    ProcessadosMensal,
    ControleBnpMensal,
    PacotesCicloMensal,
    ReprocessamentoMensal,
    TempoProcesExpurgoMensal,
    TempoProcesPreparoMensal,
    MonthPicker,
  },
  data() {
    return {
      date: {
        monthIndex: parseInt(moment().format('MM'), 10),
        year: parseInt(moment().format('yyyy'), 10),
      },
      selectedMonth: parseInt(moment().format('MM'), 10),
    };
  },
  props: {
    unidadeIndicadores: {
      type: Number,
      required: true,
    },
  },
  methods: {
    onMonthSelection(date) {
      this.date = date;
      this.selectedMonth = date.monthIndex;
    },
  },
};
</script>

<style>

.unidade-div-align{
  left: -18px;
}

.month-picker-input {
  padding: 0.3rem 0.4rem !important;
  height: 40px;
}
</style>
