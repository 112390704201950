<template>
  <div>
    <b-row class="mb-36" >
      <b-col sm="12" md="4" lg="3" xl="3" align="left" >
        <GenericSelect
          name="unidade"
          labelKey="nome"
          route="unidade"
          :value="unidadeIndicadores"
          @input="v => $emit('change-unidade-indicadores', v)"
          disabledFirstOption
        ></GenericSelect>
      </b-col>
    </b-row>
    <br>
    <b-row class="mb-5">
      <b-col>
        <CiclosDia
          :unidadeIndicadores=unidadeIndicadores
        />
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col>
      <MateriasEsterilizados
          :unidadeIndicadores=unidadeIndicadores
        />
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col>
        <PacotesCiclo
          :unidadeIndicadores=unidadeIndicadores
        />
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col>
        <CiclosMaquina
          :unidadeIndicadores=unidadeIndicadores
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import GenericSelect from '../../../components/Form/GenericSelect';
import CiclosDia from './CiclosDia';
import MateriasEsterilizados from './MateriasEsterilizados';
import PacotesCiclo from './PacotesCiclo';
import CiclosMaquina from './CiclosMaquina';

export default {
  name: 'DashBoardProcessamento',
  components: {
    CiclosDia,
    MateriasEsterilizados,
    PacotesCiclo,
    CiclosMaquina,
    GenericSelect,
  },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    unidadeIndicadores: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style>

.unidade-div-align{
  left: -18px;
}

</style>
