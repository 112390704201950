<template>
  <MonthPickerInput
    @input="onDateSelection"
    :default-month="currentMonth"
    :default-year="currentYear"
    input-pre-filled
    lang="pt"
  />
</template>

<script>
import moment from 'moment';
import { MonthPickerInput } from 'vue-month-picker';

export default {
  name: 'MonthPicker',
  components: {
    MonthPickerInput,
  },
  props: {
    // Default value of the input, also used for edition
    value: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      required: false,
      default: null,
    },
  },

  methods: {
    onDateSelection(date) {
      this.$emit('input', date);
    },
  },

  computed: {
    currentMonth() {
      return parseInt(moment().format('MM'), 10);
    },
    currentYear() {
      return parseInt(moment().format('yyyy'), 10);
    },
  },
};
</script>

<style>
.month-picker-input {
  padding: 0.3rem 0.4rem !important;
}
.month-picker__container {
  z-index: 10;
  background-color: #FFF;
  top: 3em !important;
  right: 0em;
}
.month-picker__month {
  flex-basis: calc(33.333%) !important;
}
</style>
