<template>
  <div>
    <b-row class="mb-36" >
      <b-col sm="12" md="4" lg="3" xl="3" align="left" >
        <GenericSelect
          name="unidade"
          labelKey="nome"
          route="unidade"
          :value="unidadeIndicadores"
          @input="v => $emit('change-unidade-indicadores', v)"
          disabledFirstOption
        />
      </b-col>
      <b-col sm="9" md="4" lg="3" xl="3" align="left">
        <RangeDatePicker
          :initDate="dateRange.initDate"
          :endDate="dateRange.endDate"
          :state="null"
          :isBusy="loading"
          @selected="selectedDateInDatePicker"
        />
      </b-col>
    </b-row>
    <br>
    <b-row class="mb-5">
      <b-col>
        <ProdutividadeColaborador
          :unidadeIndicadores=unidadeIndicadores
          :dateRange="dateRange"
        />
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col>
        <ProdutividadeArea
          :unidadeIndicadores=unidadeIndicadores
          :dateRange="dateRange"
        />
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col cols="6">
        <TempoProcessamentoArea
          :unidadeIndicadores=unidadeIndicadores
          :dateRange="dateRange"
        />
      </b-col>
      <b-col cols="6">
        <TempoProcessamentoPlantao
          :unidadeIndicadores=unidadeIndicadores
          :dateRange="dateRange"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment';
import GenericSelect from '../../../components/Form/GenericSelect';
import RangeDatePicker from '../../../components/Form/RangeDatePicker';
import ProdutividadeColaborador from './ProdutividadeColaborador';
import ProdutividadeArea from './ProdutividadeArea';
import TempoProcessamentoArea from './TempoProcessamentoArea';
import TempoProcessamentoPlantao from './TempoProcessamentoPlantao';

export default {
  name: 'DashBoardProdutividade',
  components: {
    GenericSelect,
    RangeDatePicker,
    ProdutividadeColaborador,
    ProdutividadeArea,
    TempoProcessamentoArea,
    TempoProcessamentoPlantao,
  },
  data() {
    return {
      dateRange: {
        initDate: moment().subtract(7, 'days'),
        endDate: moment().endOf('day'),
      },
      loading: false,
    };
  },
  props: {
    unidadeIndicadores: {
      type: Number,
      required: true,
    },
  },
  methods: {
    selectedDateInDatePicker(initDate, endDate) {
      this.dateRange.initDate = initDate;
      this.dateRange.endDate = endDate;
    },
  },
};
</script>

<style>

.unidade-div-align{
  left: -18px;
}

</style>
