<template>
  <div>
    <b-row class="padding-data">
      <b-col cols="8" class="week">
        <b-row align-h="start">
          <h5 class="pt-1 mr-2">{{ selectedDay }}</h5>
          <b-button
            v-b-tooltip.hover.top="'Semana anterior'"
            variant="outline-light"
            @click="subtractWeek(), transparente()"
            class="arrow"
            >&#60;</b-button
          >
          <b-button
            v-b-tooltip.hover.top="'Próxima semana'"
            variant="outline-light"
            @click="addWeek(), transparente()"
            class="arrow"
            :disabled="semana"
            >&#62;</b-button
          >
        </b-row>
      </b-col>
      <b-col>
        <b-row align-h="end">
          <b-button @click="showCalendar = !showCalendar" variant="outline-light" class="view-mes">
            <calendar-icon class="icon-calendar" />
            {{ lastDayOfRange.day(6).format("MMM") }}
          </b-button>
          <Calendar
            v-if="showCalendar"
            :value="dateValue"
            @close="showCalendar = false"
            @update="changeValue"
          />
        </b-row>
      </b-col>
    </b-row>

    <b-row align-h="center" class="padding-calendar">
      <b-button
        :variant="variantDay1"
        pill
        :class="{
          'data-transp': day1,
          'data-atual': (activeId == 'link-1'  && lastDayOfRange.day(0).format('DD/MM/YYYY') === selectedDay),
          'data-normal': (activeId != 'link-1' || lastDayOfRange.day(0).format('DD/MM/YYYY') !== selectedDay),
        }"
        :disabled="day1"
        @click.prevent="activeLink('link-1')"
        v-on:click="emitToParent"
        class="ml-0"
        >{{ lastDayOfRange.day(0).format("ddd") }}<br />
        <div class="letra mt-2">{{ lastDayOfRange.date() }}</div></b-button
      >
      <b-button
        :variant="variantDay2"
        pill
        :class="{
          'data-transp': day2,
          'data-atual': (activeId == 'link-2'  && lastDayOfRange.day(1).format('DD/MM/YYYY') === selectedDay),
          'data-normal': (activeId != 'link-2' || lastDayOfRange.day(1).format('DD/MM/YYYY') !== selectedDay),
        }"
        :disabled="day2"
        @click.prevent="activeLink('link-2')"
        v-on:click="emitToParent"
        >{{ lastDayOfRange.day(1).format("ddd") }}<br />
        <div class="letra mt-2">{{ lastDayOfRange.date() }}</div></b-button
      >
      <b-button
        :variant="variantDay3"
        pill
        :class="{
          'data-transp': day3,
          'data-atual': (activeId == 'link-3' && lastDayOfRange.day(2).format('DD/MM/YYYY') === selectedDay),
          'data-normal': (activeId != 'link-3' || lastDayOfRange.day(2).format('DD/MM/YYYY') !== selectedDay),
        }"
        :disabled="day3"
        @click.prevent="activeLink('link-3')"
        v-on:click="emitToParent"
        >{{ lastDayOfRange.day(2).format("ddd") }}<br />
        <div class="letra mt-2">{{ lastDayOfRange.date() }}</div></b-button
      >
      <b-button
        :variant="variantDay4"
        pill
        :class="{
          'data-transp': day4,
          'data-atual': (activeId == 'link-4' && lastDayOfRange.day(3).format('DD/MM/YYYY') === selectedDay),
          'data-normal': (activeId != 'link-4' || lastDayOfRange.day(3).format('DD/MM/YYYY') !== selectedDay),
        }"
        :disabled="day4"
        @click.prevent="activeLink('link-4')"
        v-on:click="emitToParent"
        >{{ lastDayOfRange.day(3).format("ddd") }}<br />
        <div class="letra mt-2">{{ lastDayOfRange.date() }}</div></b-button
      >
      <b-button
        :variant="variantDay5"
        pill
        :class="{
          'data-transp': day5,
          'data-atual': (activeId == 'link-5' && lastDayOfRange.day(4).format('DD/MM/YYYY') === selectedDay),
          'data-normal': (activeId != 'link-5' || lastDayOfRange.day(4).format('DD/MM/YYYY') !== selectedDay),
        }"
        :disabled="day5"
        @click.prevent="activeLink('link-5')"
        v-on:click="emitToParent"
        >{{ lastDayOfRange.day(4).format("ddd") }}<br />
        <div class="letra mt-2">{{ lastDayOfRange.date() }}</div></b-button
      >
      <b-button
        :variant="variantDay6"
        pill
        :class="{
          'data-transp': day6,
          'data-atual': (activeId == 'link-6' && lastDayOfRange.day(5).format('DD/MM/YYYY') === selectedDay),
          'data-normal': (activeId != 'link-6' || lastDayOfRange.day(5).format('DD/MM/YYYY') !== selectedDay),
        }"
        :disabled="day6"
        @click.prevent="activeLink('link-6')"
        v-on:click="emitToParent"
        >{{ lastDayOfRange.day(5).format("ddd") }}<br />
        <div class="letra mt-2">{{ lastDayOfRange.date() }}</div></b-button
      >
      <b-button
        :variant="variantDay7"
        pill
        :class="{
          'data-transp': day7,
          'data-atual': (activeId == 'link-7' && lastDayOfRange.day(6).format('DD/MM/YYYY') === selectedDay),
          'data-normal': (activeId != 'link-7' || lastDayOfRange.day(6).format('DD/MM/YYYY') !== selectedDay),
        }"
        :disabled="day7"
        @click.prevent="activeLink('link-7')"
        v-on:click="emitToParent"
        class="mr-0"
        >{{ lastDayOfRange.day(6).format("ddd") }}<br />
        <div class="letra mt-2">{{ lastDayOfRange.date() }}</div></b-button
      >
    </b-row>
  </div>
</template>

<script>
import { CalendarIcon } from 'vue-feather-icons';
import moment from 'moment';
import Calendar from './Calendar';

export default {
  components: {
    CalendarIcon,
    Calendar,
  },

  data() {
    return {
      selectedDay: moment().format('DD/MM/YYYY'),
      semana: true,
      day1: false,
      day2: false,
      day3: false,
      day4: false,
      day5: false,
      day6: false,
      day7: false,
      activeId: null,
      lastDayOfRange: moment(),
      showCalendar: false,
      refreshCalendar: 0,
      dateValue: moment().format('YYYY-MM-DD'),
    };
  },

  methods: {
    changeValue(v) {
      this.lastDayOfRange = moment(v).day(6);
      const day = this.dateSubtract(v);
      this.activeLink(`link-${day + 1}`);
      this.$emit('onDaySelection', moment(this.selectedDay, 'DD/MM/YYYY').startOf('day'));
      this.transparente();
    },

    dateSubtract(date) {
      const now = moment(date);
      const sunday = moment(this.lastDayOfRange).startOf('week');
      const duration = moment.duration(now.diff(sunday));
      return duration.asDays();
    },

    subtractWeek() {
      this.lastDayOfRange = moment(this.lastDayOfRange).subtract(7, 'days');
    },

    addWeek() {
      this.lastDayOfRange = moment(this.lastDayOfRange).add(7, 'days');
    },

    transparente() {
      this.day1 = false;
      if (moment() <= this.lastDayOfRange.day(0).startOf('day')) {
        this.day1 = true;
      }
      this.day2 = false;
      if (moment() <= this.lastDayOfRange.day(1).startOf('day')) {
        this.day2 = true;
      }
      this.day3 = false;
      if (moment() <= this.lastDayOfRange.day(2).startOf('day')) {
        this.day3 = true;
      }
      this.day4 = false;
      if (moment() <= this.lastDayOfRange.day(3).startOf('day')) {
        this.day4 = true;
      }
      this.day5 = false;
      if (moment() <= this.lastDayOfRange.day(4).startOf('day')) {
        this.day5 = true;
      }
      this.day6 = false;
      if (moment() <= this.lastDayOfRange.day(5).startOf('day')) {
        this.day6 = true;
      }
      this.day7 = false;
      if (moment() <= this.lastDayOfRange.day(6).startOf('day')) {
        this.day7 = true;
      }
      this.semana = false;
      if (moment().week() === this.lastDayOfRange.week()) {
        this.semana = true;
      }
    },

    activeLink(linkIdent = null) {
      this.activeId = linkIdent;
      if (linkIdent === 'link-1') {
        this.selectedDay = this.lastDayOfRange.day(0).format('DD/MM/YYYY');
        this.dateValue = this.lastDayOfRange.day(0).format('YYYY-MM-DD');
      }
      if (linkIdent === 'link-2') {
        this.selectedDay = this.lastDayOfRange.day(1).format('DD/MM/YYYY');
        this.dateValue = this.lastDayOfRange.day(1).format('YYYY-MM-DD');
      }
      if (linkIdent === 'link-3') {
        this.selectedDay = this.lastDayOfRange.day(2).format('DD/MM/YYYY');
        this.dateValue = this.lastDayOfRange.day(2).format('YYYY-MM-DD');
      }
      if (linkIdent === 'link-4') {
        this.selectedDay = this.lastDayOfRange.day(3).format('DD/MM/YYYY');
        this.dateValue = this.lastDayOfRange.day(3).format('YYYY-MM-DD');
      }
      if (linkIdent === 'link-5') {
        this.selectedDay = this.lastDayOfRange.day(4).format('DD/MM/YYYY');
        this.dateValue = this.lastDayOfRange.day(4).format('YYYY-MM-DD');
      }
      if (linkIdent === 'link-6') {
        this.selectedDay = this.lastDayOfRange.day(5).format('DD/MM/YYYY');
        this.dateValue = this.lastDayOfRange.day(5).format('YYYY-MM-DD');
      }
      if (linkIdent === 'link-7') {
        this.selectedDay = this.lastDayOfRange.day(6).format('DD/MM/YYYY');
        this.dateValue = this.lastDayOfRange.day(6).format('YYYY-MM-DD');
      }
    },

    emitToParent() {
      this.$emit('onDaySelection', moment(this.selectedDay, 'DD/MM/YYYY').startOf('day'));
    },
  },

  async mounted() {
    this.activeLink(`link-${moment().day() + 1}`);
    this.transparente();
  },

  computed: {
    variantDay1() {
      return !this.day1 ? 'outline-light' : 'secundary';
    },
    variantDay2() {
      return !this.day2 ? 'outline-light' : 'secundary';
    },
    variantDay3() {
      return !this.day3 ? 'outline-light' : 'secundary';
    },
    variantDay4() {
      return !this.day4 ? 'outline-light' : 'secundary';
    },
    variantDay5() {
      return !this.day5 ? 'outline-light' : 'secundary';
    },
    variantDay6() {
      return !this.day6 ? 'outline-light' : 'secundary';
    },
    variantDay7() {
      return !this.day7 ? 'outline-light' : 'secundary';
    },
  },
};
</script>

<style>
.padding-data {
  margin-left: 1px;
  margin-right: 1px;
}

.week {
  padding-bottom: 24px;
}

.arrow {
  background-color: white;
  color: black;
  border: none;
  height: 40px;
  font-size: 24px;
  align-self: center;
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: 2px;
  margin-left: 2px;
}

.view-mes {
  height: 40px;
  width: 100px;
  border: none;
  color: rgb(94, 98, 122);
  background-color: rgb(240, 241, 252);
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: .25rem;
}

.icon-calendar {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.padding-calendar {
  padding-top: 2px;
  margin-top: 15px;
  display: inline;
}

.data-atual {
  background-color: #21CCA9;
  border: none;
  font-size: 13px;
  width: 14%;
  height: 80px;
  padding-right: 3px;
  padding-left: 3px;
  margin: 2px;
}

.data-normal {
  border: none;
  font-size: 13px;
  width: 14%;
  height: 80px;
  padding-right: 3px;
  padding-left: 3px;
  margin: 2px;
  background-color: white;
  color: black;
}

.data-transp {
  border: none;
  font-size: 13px;
  width: 14%;
  height: 80px;
  padding-right: 3px;
  padding-left: 3px;
  margin: 2px;
  background-color: white;
  color: lightgray;
}

.letra {
  font-size: 16px;
}

.btn.disabled, .btn:disabled {
  opacity: unset;
}
</style>
