<template>
  <div>
    <b-row>
      <b-col>
        <h4 class="row-dash-title">Tempo médio de processamento por área</h4>
      </b-col>
    </b-row>
    <b-overlay
      :show="loading"
      rounded="sm"
      class="dashboard-overlay"
    >
      <div class="dash-box">
        <b-row align-h="end">
          <b-col cols="2" class="mt-3">
            <div v-if="loadingExport" class="center-div-content">
              <b-spinner class="load-icon"/>
            </div>
            <div @click="exportData" class="center-div-content" v-else>
              <external-link-icon
                style="cursor:pointer"
                size="1.5x"
                v-b-tooltip.hover.right="'Exportar dados'"
              />
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col cols="12">
            <ECharts :option="chartOptions" />
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import ECharts from 'vue-echarts';

import moment from 'moment';
import GenericApi from '../../../services/genericRequest';

export default {
  name: 'TempoProcessamentoArea',
  components: {
    ECharts,
  },
  inject: ['getExportedData', 'createXlsx'],
  props: {
    unidadeIndicadores: {
      type: Number,
      required: true,
    },
    dateRange: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mountedFirstTime: false,
      loading: false,
      loadingExport: false,
      chartOptions: {
        color: ['#21CCA9', '#209F85'],
        xAxis: [
          {
            show: true,
            type: 'category',
            axisTick: { show: false },
            data: [],
          },
        ],
        yAxis: [
          {
            show: false,
          },
        ],
        series: [
          {
            name: 'tempoArea',
            type: 'bar',
            stack: 'tempoArea',
            data: [],
            label: {
              show: true,
              position: 'top',
              formatter: (v) => this.minutesToHourMinutes(v.value),
            },
          },
        ],
      },
    };
  },

  mounted() {
    this.updateChart();
    this.mountedFirstTime = true;
  },

  watch: {
    dateRange: {
      deep: true,
      immediate: true,
      async handler() {
        if (this.mountedFirstTime) this.updateChart();
      },
    },
    unidadeIndicadores: {
      deep: true,
      immediate: true,
      async handler() {
        if (this.mountedFirstTime) this.updateChart();
      },
    },
  },

  methods: {
    minutesToHourMinutes(min) {
      if (!min) return '0h';
      const hours = Math.floor(min / 60);
      const minutes = Math.ceil(min % 60);
      const hoursString = hours ? `${hours}h` : '';
      const minutesString = minutes ? `${minutes}m`.padStart(3, '0') : '';
      return `${hoursString}${minutesString}`;
    },
    millisecondsToMin(time) {
      const millisecondsInSeconds = 1000;
      const secondsInMin = 60;
      return Math.floor(time / (millisecondsInSeconds * secondsInMin));
    },
    async updateChart() {
      const processamentos = await this.getChartData();
      this.updateChartData(processamentos);
    },

    resetChartData() {
      this.chartOptions.series[0].data.forEach((processoData) => {
        processoData.value = 0;
      });
    },

    async getChartData() {
      const filters = {
        id_unidade: this.unidadeIndicadores,
        initDate: this.dateRange.initDate.toISOString(),
        endDate: this.dateRange.endDate.toISOString(),
      };
      try {
        this.loading = true;

        const processamentos = await GenericApi.getWithoutPagination(
          { filters },
          'home/tempoProcessamentoGroupedByProcesso',
        );
        return processamentos;
      } catch (error) {
        let errorMessage = 'Ocorreu um problema ao recuperar os dados relativos à produtividade por área';
        if (
          error.response
            && error.response.data
            && error.response.data.error
            && error.response.data.error.errorMessage
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        await swal({
          title: 'Erro',
          text: errorMessage,
          icon: 'error',
          button: 'Continuar...',
        });
        return false;
      } finally {
        this.loading = false;
      }
    },

    updateChartData(processamentos) {
      this.chartOptions.xAxis[0].data = [];
      this.chartOptions.series[0].data = processamentos.map((processo, index) => {
        this.chartOptions.xAxis[0].data[index] = processo.tipoProcesso.charAt(0).toUpperCase()
          + processo.tipoProcesso.slice(1);
        const meanInMinutes = this.millisecondsToMin(processo.mediaTempo);
        return { value: meanInMinutes };
      });
    },

    async exportData() {
      this.loadingExport = true;
      const filters = {
        id_unidade: this.unidadeIndicadores,
        initDate: this.dateRange.initDate.toISOString(),
        endDate: this.dateRange.endDate.toISOString(),
        tipoProcesso: ['expurgo', 'preparo'],
      };
      const exportedData = await this.getExportedData(
        'home/tempoProcessamentoPlantao/export',
        filters,
        'Erro ao recuperar dados tempo de processamento para exportação',

      );
      if (!exportedData) {
        this.loadingExport = false;
        return;
      }
      const processosExported = exportedData.map((processo) => {
        const data_processamento = moment(processo.data_processamento);
        const data_finalizacao = moment(processo.data_finalizacao);
        const tempoProcessamento = data_finalizacao.diff(data_processamento, 'minutes');
        const { vouchers_inicio } = processo;
        let voucher_recebimento;
        let voucher_transferencia;
        if (vouchers_inicio) {
          voucher_recebimento = vouchers_inicio.find((voucher) => voucher.tipo === 'recebimento');
          voucher_transferencia = vouchers_inicio.find((voucher) => voucher.tipo === 'transferenciaExpurgo');
        }
        processo.evenOrOddDay = processo.evenOrOddDay === 'odd' ? 'ímpar' : 'par';
        processo.Turno = `${processo.Turno} - ${processo.evenOrOddDay}`;
        delete processo.evenOrOddDay;
        delete processo.vouchers_inicio;
        return {
          ...processo,
          data_processamento: data_processamento.format('DD/MM/YYYY HH:mm:ss'),
          data_finalizacao: data_finalizacao.isValid()
            ? data_finalizacao.format('DD/MM/YYYY HH:mm:ss')
            : '-',
          'Tempo Processamento': data_finalizacao.isValid() ? this.minutesToHourMinutes(tempoProcessamento) : '-',
          Voucher: voucher_recebimento && `R${voucher_recebimento.numero}`,
          // eslint-disable-next-line quote-props
          'Transferência': voucher_transferencia && `T${voucher_transferencia.numero}`,
        };
      });
      this.createXlsx(processosExported, 'Processamento por Area');
      this.loadingExport = false;
    },
  },
};
</script>

<style scoped>
.meta-box {
  width: 45%;
  font-size: 2.2rem;
  border: 1px solid #cfd1e5;
  border-radius: 10px;
  padding: 12px;
  font-weight: 500;
}
.info-text {
  color: rgba(126, 130, 159, 1);
  font-weight: normal;
  font-size: 0.7rem;
}

.result-meta {
  font-size: 0.7rem;
  position: absolute;
  top: 2.9rem;
  margin-left: 10px;
}
.result-meta.negativo {
  color: red;
}
.result-meta.positivo {
  color: green;
}
</style>
